// var debug = false;
// // platform!='webapp'
// if (debug==false) {
// 	window.console = {
// 		log : function(args){},
// 		init_log : function(args){},
// 		info : function(args){},
// 		warn : function(args){},
// 		debug : function(args){},
// 		error : function(args){},
// 		dir : function(args){},
// 	}
// 	console = window.console;
// }

var consoleHolder = console;
function debug(bool){
	if(!bool){
		consoleHolder = console;
		console = {};
		console.log = function(){};
		console.init_log = function(){};
		console.info = function(){};
		console.warn = function(){};
		console.debug = function(){};
		console.error = function(){};
		console.dir = function(){};
	}else{
		console = consoleHolder;
	}
}
debug(window.debugActivate);


var window_width = window.innerWidth;

// var supportsOrientationChange = "onorientationchange" in window,
// 	orientationEvent = supportsOrientationChange ? "orientationchange" : "resize";
//
// window.addEventListener(orientationEvent, function(){
// 	window.location.reload();
// }, false);

// // doubleclick fix
// var last_click_x = 0, last_click_y = 0, last_click_time = new Date().getTime();
// $("a").live("click", function(e) {
// 	var click_x = e["pageX"], click_y = e["pageY"], click_time = e["timeStamp"];
// 	if (click_x && click_y && click_time &&
// 		(Math.abs(click_x - last_click_x) < 10) &&
// 		(Math.abs(click_y - last_click_y) < 10) &&
// 		(click_time - last_click_time) < 1000) {
// 			e.stopImmediatePropagation();
// 			return false;
// 	}
// 	last_click_x = click_x;
// 	last_click_y = click_y;
// 	last_click_time = click_time;
// });



// usable events
var hasTouch = 'ontouchstart' in window,
RESIZE_EV = 'onorientationchange' in window ? 'orientationchange' : 'resize',
START_EV = hasTouch ? 'touchstart' : 'mousedown',
MOVE_EV = hasTouch ? 'touchmove' : 'mousemove',
//END_EV = hasTouch ? 'touchend' : 'mouseup',
END_EV = hasTouch ? 'click' : 'mouseup',
CANCEL_EV = hasTouch ? 'touchcancel' : 'mouseup';

// check isArray
if(!Array.isArray) {
  Array.isArray = function (vArg) {
    return Object.prototype.toString.call(vArg) === "[object Array]";
  };
}


// Add a new method available on all function values
Function.prototype.getName = function(args){
	for (var o in this) {
		if (args.callee===this[o]){
			console.log(o);
			return o;
		}
	}
};




// if (Function.prototype.name === undefined){
//   // Add a custom property to all function values
//   // that actually invokes a method to get the value
//   Object.defineProperty(Function.prototype,'name',{
//     get:function(){
//       return /function ([^(]*)/.exec( this+"" )[1];
//     }
//   });
// }


// http://stackoverflow.com/questions/979256/sorting-an-array-of-javascript-objects
/*
// Now you can sort by any field at will...

var homes = [{

   "h_id": "3",
   "city": "Dallas",
   "state": "TX",
   "zip": "75201",
   "price": "162500"

}, {

   "h_id": "4",
   "city": "Bevery Hills",
   "state": "CA",
   "zip": "90210",
   "price": "319250"

}, {

   "h_id": "5",
   "city": "New York",
   "state": "NY",
   "zip": "00010",
   "price": "962500"

}];

// Sort by price high to low
homes.sort(sort_by('price', true, parseInt));

// Sort by city, case-insensitive, A-Z
homes.sort(sort_by('city', false, function(a){return a.toUpperCase()}));

*/
var sort_by = function(field, reverse, primer){

   var key = function (x) {return primer ? primer(x[field]) : x[field]};

   return function (a,b) {
       var A = key(a), B = key(b);
       return ((A < B) ? -1 : (A > B) ? +1 : 0) * [-1,1][+!!reverse];
   }
}


function getBase64FromImageUrl(URL, func) {
    var img = new Image();
    img.src = URL;
    img.onload = function () {
	    var canvas = document.createElement("canvas");
	    canvas.width =this.width;
	    canvas.height =this.height;

	    var ctx = canvas.getContext("2d");
	    ctx.drawImage(this, 0, 0);

		var dataURL = canvas.toDataURL("image/jpeg");

		// alert(  dataURL.replace(/^data:image\/(png|jpg);base64,/, ""));

		//console.log('1.--> '+dataURL);
		if (func) {
			return func(dataURL);
		} else {
			return dataURL;
		}

    }
}


function get_random_color() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++ ) {
        color += letters[Math.round(Math.random() * 15)];
    }
    return color;
}