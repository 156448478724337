// var stopPropagation = '';
// stopPropagation += 'if(event.stopPropagation) {';
// stopPropagation += ' event.stopPropagation();';
// stopPropagation += '} else {';
// stopPropagation += ' event.cancelBubble = true;';
// stopPropagation += '}';
// stopPropagation += 'event.preventDefault();';
// stopPropagation += 'return false;';

var spinner_container = document.getElementById('spinner'),
	searchbar_container = document.getElementById('searchbar'),
	content_wrapper = document.getElementById('content'),
	content_container = document.getElementById('content'),
	scroller_container = document.getElementById('scroller'),
	start_scroller_container = document.getElementById('start_scroller_container');

var pages = function(){

	return {
	// helper function
	'content': {
		'name':'content',
		'toolbar':false,
		'load': function(html, cb){

			this.html = html;
			this.cb = cb;

			return function(html, cb){
				//scroller_container.innerHTML='';
				console.log('### pages.content.load()');


				// clear start interval
				if (!_.isUndefined(pages.start.interval)) {
					pages.start.interval = window.clearInterval(pages.start.interval);
					console.log('window.clearInterval(pages.start.interval);')
				}

				var hash = app.hash.get();
				if ( hash['page'] === 'recipes' && hash['method'] === false) {
					searchbar_container.style.display='block';
					//$('#content_wrapper').height( app.height - app.titlebar.height - app.toolbar.height - $('#searchbar').height() );
				} else {
					searchbar_container.style.display='none';
					
					// var contentHeight = app.height - app.titlebar.height - app.toolbar.height;
					
					//$('#content_wrapper').height( contentHeight );
					//$('#content').height( contentHeight );
					//$('#scroller').height( contentHeight );
				}

				scroller_container.innerHTML=html;

				if (scroller) {
					app.scroller.refresh();
				}
				$('#img_recipes_large').removeClass('active');

				app.spinner.start(function(){
					if( $('ul#content_inner.list').length>0 ){
						app.scroller.scrollBack();
					}
				}, 800);

				if (cb) {
					cb();
				}
			}; // EO: return content load

		}() // EO: content load

	},

	'start': {
		'name':'start',
		'toolbar':true,
		'title':'Glutenfrei',
		'nav_title':'Start',
		'content':'',
		'item_count': 40,
		'timer':10000,
		'setfav':function (id) {
			return function(){
				var chkFavorites = pages['favorites'].isActive(id),
					chkShopping_list = pages['shopping_list'].isActive(id),
					isOnFavorites = chkFavorites[0],
					isOnShopping_list = chkShopping_list[0],
					favorites_class = (isOnFavorites)?'star':'',
					chkShopping_list_class = (isOnShopping_list)?'cart':'',
                    recipe_title = $('.recipe_title');

				recipe_title.removeClass('star');
				recipe_title.removeClass('cart');
				recipe_title.addClass(favorites_class);
				recipe_title.addClass(chkShopping_list_class);

			}();
		},
		'init': function(){

			return function(){

				var ret = [];

				// start
				ret.push('<ul id="start">');
				ret.push(	'<li id="start_title" on'+END_EV+'="pages.start.randomRecipe();"><h2>Rezept-Tipp</h2></li>');
				ret.push(	'<li id="start_recipe_block">');

				ret.push('<div id="start_wrapper">');
				ret.push(	'<div id="start_scroller">');

				ret.push(		'<ul id="start_recipe" style="border-bottom:1px solid #97bf0d;">');

				pages.start.random_counter = 1;
				pages.start.random_items = _.sample(recipes.items, pages.start.item_count);


				// for (var i = 0; i < random_items.length; i++) {
					var r = pages.start.random_items[pages.start.random_counter];

					var id = r['id'];
					var title = r['title'];
					var image = 'img/recipes_large/'+r['image']['$'];

					ret.push(			'<li class="start_item">');
					ret.push(				'<a href="#recipes,'+id+'"');
					ret.push(					' class="start_item"');
					ret.push(					' style="background-image:url(\''+image+'\')"');
					// ret.push(					' on'+END_EV+'="app.hash.set(\'recipes,'+id+'\')"');
                    ret.push(					' on'+END_EV+'="app.hash.set(this.href.split(\'#\')[1])"');
					ret.push(					' data-platform="'+r['image']['@platform']+'"');
					ret.push(					' data-licence="'+r['image']['@licence']+'"');
					ret.push(					' data-imageid="'+r['image']['@imageid']+'"');
					ret.push(					'>');
					ret.push(					'<span class="recipe_title">');
					ret.push(						title);
					ret.push(						'<span class="index_reload">');
					ret.push(						'</span>');
					ret.push(					'</span>');
					ret.push(				'</a>');
					ret.push(				'<div class="image_data">');
					ret.push(				'Foto:'+r['image']['@licence']+' @ '+r['image']['@platform']);
					ret.push(				'</div>');
					ret.push(			'</li>');
				// } // for if used iscoll for snapp to element

				ret.push(		'</ul>');

				ret.push(	'</div>'); // start_scroller
				ret.push('</div>'); // start_wrapper

				ret.push(	'</li>');
				ret.push('</ul>');

				var html = ret.join('');

				app.spinner.start(function(){
					//scroller_container.innerHTML = html;
					pages['content'].load(html);

					var w = window.innerWidth,
						body =  $('body'),
                        titlebar = $('#titlebar'),
                        toolbar = $('#toolbar'),
						c_h = body.height() - (titlebar.height() + toolbar.height()),
						t_h = $('#start_title').height(),
						h = c_h-t_h;
					
					$('#content_wrapper').height( body.height() - toolbar.height());
					//$('#content').height( c_h );
					//$('#scroller').height( c_h );

					$('#start_recipe_block').height(c_h).width(w);
					$('#start_wrapper').height(c_h).width(w);
					$('#start_scroller').height(c_h).width(w * pages.start.item_count);
					$('.start_item').height(c_h).width(w);

					$('#start').bind(MOVE_EV, function (e) {
						e.preventDefault();
						e.stopPropagation();
					});

					console.info('home.init');

					pages.searchbar.init();

					scroller_container.style.top = titlebar.height();
					searchbar_container.style.display = 'none';

					// app.scroller.destroy();

					if (!_.isUndefined(pages.start.interval)) {
						window.clearInterval(pages.start.interval);
					}

					pages.start.interval = window.setInterval(function () {
						pages.start.randomRecipe();
					}, pages.start.timer);

					_.delay(function(){
						$('#img_recipes_large').css({
							'width':0,
							'height':0
						});
					}, 500);

				}, 1000); // spinner.start timeout

			}; // EO: return start init

		}(), // EO: start init

		'randomRecipe': function () {

			return function () {
				window.clearInterval(pages.start.interval);
				app.spinner.start(function(){

					pages.start.interval = window.setInterval(function () {
						pages.start.randomRecipe();
					}, pages.start.timer);

					pages.start.random_counter++;

					var r = pages.start.random_items[pages.start.random_counter];
					var id = r['id'];
					var title = r['title']+'<span class="index_reload"></span>';
					var image = 'img/recipes_large/'+r['image']['$'];

					var $item = $('a.start_item');
					$item.css({
						'background-image':'url(\''+image+'\')'
					});
					$item.data('platform', r['image']['@platform']);
					$item.data('licence', r['image']['@licence']);
					$item.data('imageid', r['image']['@imageid']);
					
					$item.attr('href', '#recipes,'+id);
					
					$item.attr('href', '#recipes,'+id);
					$('.recipe_title').html(title);
					$('.image_data').html('Foto: '+r['image']['@licence']+' @ '+r['image']['@platform']);

					pages.start.setfav(id);

					if (pages.start.random_counter === pages.start.random_items.length-1) {
						pages.start.random_counter=0;
					}

				}, 1000);
			}; // EO: return start randomRecipe
		}() // EO: start randomRecipe
	},

	'recipes': {
		'name':'recipes',
		'toolbar':true,
		'title':'Rezepte',
		'content':'',
		'init':function(){

			return function(){
				app.spinner.start();

				if ($('#search').val() !== '' || $('.category').val() !== '') {
					console.info('recipes.init -> submit');
					$('form#searchform_form').submit();
				} else {
					var html = pages.recipes.list(recipes.items, this.name);
					pages['content'].load(html);

					app.scroller.refresh();
					$('ul.list li a').each(function (i, e) {
						$this = $(this);
						$this.on('tapEvt', function (evt) {

                            $a = $(this); //.find('a');
                            var list = $a.data('list');
                            var id = $a.data('id');

                            pages.recipes.listClick($a, list, id);

							console.log($a.parent());

                            alert([list, id]);

                            $a.parent().addClass('active');
							// evt.stopPropagation();
						})
					})

				}
				console.info('recipes.init');
			}; // EO: return recipes init

		}(), // EO: recipes init

		'page':function(pid){

			this.pid = pid;

			return function(pid){
				console.log('recipes.page('+pid+')');
				var element = pages.recipes.find(pid);
				var html = pages.recipes.detail(element);
				pages['content'].load(html);
				// app.scroller.copy();
				app.scroller.refresh();
				app.scroller.scrollTo(0, 0, 0);
			}; // EO: return recipes page
		}(), // EO: recipes page

		'load': function(html){
			pages['content'].load(html);
		},

		listClick: function(elem, listname, id, startEv){

            var id = null;

            this.id = id;
            this.elem = elem;
            this.listname = listname;
            this.startEv = startEv;

            console.log(['elem, listname, id, startEv', elem, listname, id, startEv]);


			return function(elem, listname, id){

                console.log(['pages.recipes.listClick.id', pages.recipes.listClick.id, id]);

                //alert('pages.recipes.listClick.id\n'+pages.recipes.listClick.id+'\n'+id);

                    // if(pages.recipes.listClick.id == id){
                        app.scroller.lastY=$('#content_wrapper').scrollTop();

                        $(elem).parent().addClass('clicked');
                        $(elem).addClass('clicked');
                        setTimeout(function () {
                            app.hash.set([listname, id]);
                            document.getElementById('content_wrapper').scrollTop = 0;
                            window.scrollTo(0, 0);
                        }, 300);
                        //pages.recipes.listClick.id=null;

                    // }

			}; // EO: return recipes listClick
		}(), // EO: recipes listClick

		list: function(items_array, listname){

			return function(items_array, listname){
				console.log(['list: function(', items_array, listname, '){']);

				var ret = [],
					no_entry_class='',
					favorites_data=app.db.get('favorites'),
					shopping_list_data=app.db.get('shopping_list');

// var return_ing=[];

				if(items_array.length>0){
					items_array.sort( sort_by('title', true) );
					for (var i = 0; i < items_array.length; i++) {
						var r=items_array[i];

						// list elements
						console.log(['for pages['+this.name+'].list', r])

						if (!_.isUndefined(r)) {

							var chkFavorites = pages['favorites'].isActive(r['id'], favorites_data),
								chkShopping_list = pages['shopping_list'].isActive(r['id'], shopping_list_data),
								isOnFavorites = chkFavorites[0],
								isOnShopping_list = chkShopping_list[0],
								factor = pages.recipes.factor(r),
								act_amount = parseInt(r['@amount']) * factor;

							ret.push('<li class="r_'+r['id']+'"');
                            // ret.push(' onclick="pages.recipes.listClick(this,\''+listname+'\', '+r['id']+');"');
							//ret.push(' data-time="'+(new Date().getTime())+'"');
							ret.push('>');
							ret.push('<a ');
                            // ret.push('onclick="app.hash.set([\''+listname+'\', '+r['id']+'])" ');

                            ret.push('data-list="'+listname+'" ');
                            ret.push('data-id="'+r['id']+'" ');
                            ret.push('onclick="pages.recipes.listClick(this,\''+listname+'\', '+r['id']+');" ');

                            // ret.push('onclick="alert(\'click on recipe\')" ');

                            //ret.push('on'+START_EV+'="pages.recipes.listClick.id='+r['id']+';" ');
                            //ret.push('on'+MOVE_EV +'="pages.recipes.listClick.id=null;" ');
                            //ret.push('on'+END_EV  +'="pages.recipes.listClick(this,\''+listname+'\', '+r['id']+');" ');
                            //ret.push('ontap="pages.recipes.listClick(this,\''+listname+'\', '+r['id']+');" ');

                            ret.push('>');

                            // ret.push('<a href="javascript:;" onclick="pages.recipes.listClick(this,\''+listname+'\', '+r['id']+');">');


							// Image
							ret.push('<div class="image" ');
                            ret.push('style="background-image:url(\'img/recipes_thumbs/'+r['image']['$']+'\')"');
							
							ret.push(' data-platform="'+r['image']['@platform']+'"');
							ret.push(' data-licence="'+r['image']['@licence']+'"');
							ret.push(' data-imageid="'+r['image']['@imageid']+'"');
							
                            // ret.push('on'+END_EV+'="pages.recipes.listClick(this,\''+listname+'\', '+r['id']+');" ');
                            ret.push('>');

							// Sprite but image would be 6000px height on 50 thumbs
							//ret.push('<div class="image recipes_thumbs-sprite recipes_thumbs-'+r['image'].split('.')[0]+'">');
							var status = '';
							if (isOnShopping_list === true) {
								status = 'shopping_list';
							}
							else if (isOnFavorites === true) {
								status = 'favorites favorites_'+chkFavorites[1]["favorites_stars"].toString().replace(/\./, '_'); // JSON.stringify(chkFavorites)
							}
							ret.push('<div id="recipes_status_icon" class="recipes_status_icon'+r['id']+' '+status+'">&nbsp;</div>');
							ret.push('</div>');

							ret.push('<div class="desc">');
							// title
							ret.push('<h3>');
							ret.push(r['title']);
							ret.push('</h3>');

							// Subtitle
							ret.push('<em>');
							ret.push('Rezept für ');
							ret.push(act_amount);
							ret.push(' ');
							ret.push(r['@unit']);
							ret.push('</em>');
							ret.push('</div>');

							ret.push('</a>');

// return_ing.push( {
// 	'id':r.id,
// 	'title':r.title
// } );
// var ing = r['ingredients']['ingredient'];
// if (_.where_(ing, {'$':'Ei'}).length > 0) {
// 	return_ing.push( [
// 		r['title'],
// 		_.where_(ing, {'$':'Ei'})
// 	] );
// }
							// favorites delete button
							// shopping delete button
							if(listname === 'recipes' || listname === 'searchbar'){
								ret.push('<a class="arrow_btn" ');
								// ret.push('arrow_btn');
                                ret.push('on'+END_EV+'="pages.recipes.listClick(this,\''+listname+'\', '+r['id']+');" ');
                                ret.push('>');
								ret.push('</a>');
							}
							else if(listname === 'favorites' || listname === 'shopping_list'){
								ret.push('<a class="delete_btn"');
								ret.push(' href="javascript:');
								ret.push(	';"');
								ret.push(' on'+END_EV+'="');
									ret.push('pages.'+listname+'.button(\''+r['id']+'\',true);');
									// ret.push('pages.'+listname+'.setButtons(\''+r['id']+'\',false);');
									ret.push(' app.scroller.refresh();');
									// ret.push(' event.stopPropagation()');
									ret.push('">');
								ret.push('<span>');
								//ret.push('delete_btn')
								ret.push('</span>');
								ret.push('</a>');
							}

							if(listname === 'shopping_list'){
								// Zutaten ----------------------------------
								var ings_html = pages.recipes.ingredients( r );
								ret.push( ings_html );
							}
							ret.push('</li>');
						} // EO:for recipes
					}
					if(listname === 'shopping_list'){
						// Mail ----------------------------------
						var shopping_list = pages.shopping_list.where();
						if (shopping_list.length > 0) {
							var mail_btn_txt = (shopping_list.length === 1)? 'Dieses Rezept mailen': 'Diese Rezepte mailen';
							//var mail_link_txt = pages.shopping_list.mail(shopping_list);
							ret.push('<li class="button mail"');
							ret.push('>');
							ret.push('<a href="javascript:;"');
                            ret.push(' on'+END_EV+'="pages.shopping_list.mail()"');
							// ret.push(' onclick="pages.shopping_list.mail()"');
							ret.push(' id="mail_btn"');
							ret.push('>');
							ret.push(mail_btn_txt);
							ret.push('</button>');
							ret.push('</li>');
						}
					}

				} else {
					no_entry_class='activate';
				}
				ret.push('<li class="no_entry '+no_entry_class+'">');
				ret.push('<h3>');
				ret.push('Keine Einträge vorhanden');
				ret.push('</h3>');
				ret.push('</li>');

				var html = '<ul id="content_inner" class="list '+listname+'">'+ret.join('')+'</ul>';


// console.log('######### FIND #########');
//
// app.return_ing = _.sortBy(return_ing, 'id');
//
// console.log(app.return_ing);
// console.log('######### EO:FIND #########');


				return html;
			}; // EO: return recipes list
		}(), // EO: recipes list

		detail: function(item){

			return function(item){
				var name = this.name;

				var ret = [],
					r = item;

				console.log(['pages['+name+'].init', r]);

				var chkFavorites = pages['favorites'].isActive(r['id']),
					chkShopping_list = pages['shopping_list'].isActive(r['id']),
					isOnFavorites = chkFavorites[0],
					isOnShopping_list = chkShopping_list[0];

				// ret.push('<input type="hidden" id="isOnfavorites" value="'+isOnFavorites+'" />');
				// ret.push('<input type="hidden" id="isOnshopping_list" value="'+isOnShopping_list+'" />');


				var factor = pages.recipes.factor(r),
					act_amount = parseInt(r['@amount']) * factor;

				// Titel ----------------------------------
				ret.push('<li class="title">');
				ret.push('<h3>');
				ret.push(r['title']);
				ret.push('</h3>');
				ret.push('</li>');

				// Bild / QRY / Rezept / favorite / shopping_list ----------------------------------
				ret.push('<li class="data_block_li">');
				ret.push('<div class="data_block">'); // DATA_BLOCK

				// Image
				ret.push('<div class="data_cell">'); // EO: DATA_CELLE
				ret.push(	'<a');
				ret.push(	' href="javascript:;"');
				ret.push(	' class="image"');
				ret.push(	' style="background-image:url(\'img/recipes_detail/'+r['image']['$']+'\')"');
				ret.push(	' on'+END_EV+'="pages.recipes.image.show('+r['id']+')"');
				ret.push(	'>');
				var status = '';
				if (isOnShopping_list==true) {
					status = 'shopping_list';
				}
				else if (isOnFavorites==true) {
					status = 'favorites favorites_'+chkFavorites[1]["favorites_stars"].toString().replace(/\./, '_'); // JSON.stringify(chkFavorites)
				}
				ret.push(		'<div id="recipes_status_icon" class="'+status+'">&nbsp;</div>');
				ret.push(	'</a>');
				ret.push(	'<div class="image_data">');
				ret.push(		'Foto:');
				ret.push(		' ' + r['image']['@licence']+' ');
				ret.push(		' @ '+r['image']['@platform']+' ');
				ret.push(	'</div>');
				ret.push('</div>'); // EO: 1. DATA_CELLE
				// EO: Image

				ret.push('<div id="block" class="data_cell">'); // 2. BLOCK DATA_CELLE

				// Subtitle

				ret.push(	'<h4>');
				// ret.push(	'Rezept für ');
				ret.push(		'<span id="qty_txt">');
				ret.push(			act_amount);
				ret.push(		'</span> ');
				ret.push(		r['@unit']);
				ret.push(	'</h4>');
				// EO: Subtitle

				// SET QTY DOWN/UP

				ret.push(	'<div>');
				ret.push(		'<a href="javascript:;" on'+END_EV+'="');
				ret.push(		'pages.shopping_list.set_qty(\'down\','+r['id']+')');
				ret.push(		';"');
				// ret.push(		'<a href="javascript:;"');
				// ret.push(		' onclick="pages.shopping_list.set_qty(\'down\', '+r['id']+');"');
				ret.push(		' class="minus"');
				ret.push(		'>');
				ret.push(			'&nbsp;');
				ret.push(		'</a>');

				ret.push(		'<input type="number"');
				ret.push(		' name="number"');
				ret.push(		' value="'+act_amount+'"');
				ret.push(		' id="qty"');
				ret.push(		' disabled="disabled" />');

				ret.push(		'<input type="hidden"');
				ret.push(		' value="'+factor+'"');
				ret.push(		' id="factor" />');

				ret.push(		'<input type="hidden"');
				ret.push(		' value="'+r['@amount']+'"');
				ret.push(		' id="recipe_amount" />');

				ret.push(		'<a href="javascript:;" on'+END_EV+'="');
				ret.push(		'pages.shopping_list.set_qty(\'up\','+r['id']+')');
				ret.push(		';"');
				// ret.push(		'<a href="javascript:;"');
				// ret.push(		' onclick="pages.shopping_list.set_qty(\'up\', '+r['id']+');"');
				ret.push(		' class="plus"');
				ret.push(		'>');
				ret.push(			'&nbsp;');
				ret.push(		'</a>');
				ret.push(	'</div>');
				// EO: SET QTY DOWN/UP

				// SET FAVORITE & SHOPPING_LIST

				ret.push(	'<div id="add_to_block">');

				ret.push(		'<div class="stars" on'+END_EV+'="event.stopPropagation();">');
				ret.push(		'<a');
				ret.push(		' href="javascript:;" on'+END_EV+'="pages.favorites.set_star(0,'+r['id']+');"');
				ret.push(		' class="star_delete delete_btn">');
				ret.push(		'<span></span>');
				// ret.push(		'delete');
				ret.push(		'</a>');

				// 5 stars halfstars solution

				var favorites_stars = ( _.isUndefined( chkFavorites[1] ) )? 0: chkFavorites[1]['favorites_stars'];
				var chkStars = parseFloat(favorites_stars);

				// alert(chkStars)

				for (var i = 1; i <= 6; i++) {
					var star = i/2;
					var d_sec = (i%2)?'l':'r';
					var is_act = (isOnFavorites==true && star<=chkStars) ? ' act ':'';

					// console.error('######## chkStars')
					// console.log(chkStars)
					// console.error('######## is_act')
					// console.log(is_act)



					ret.push(		'<a');
					ret.push(		' href="javascript:;" on'+END_EV+'="pages.favorites.set_star('+star+','+r['id']+');"');
					ret.push(		' class="star '+d_sec+is_act+'">');
					ret.push(i);
					//ret.push(star);
					ret.push(		'</a>');
				}
				ret.push(		'</div>');
				ret.push(		'<input type="hidden" id="favorites_stars" value="" />');

				// stars
				// 	star.l
				// 	star.r

				// ret.push(		'<a href="javascript:pages.favorites.button('+r['id']+');"');
				// ret.push(		'<a href="javascript:pages.favorites.set_star(1, '+r['id']+');"');
				ret.push(		'<a href="javascript:;" on'+END_EV+'="pages.favorites.showStars();"');
				ret.push(		' id="add_to_favorites"');
				ret.push(		' class="'+((isOnFavorites==true)?' active':'')+'"');
				// ret.push(		' onclick="pages.favorites.button('+r['id']+');"');
				ret.push(		'>');
				ret.push(		'&nbsp;');
				ret.push(		'</a>');

				ret.push(		'<a href="javascript:;" on'+END_EV+'="pages.shopping_list.button('+r['id']+');"');
				ret.push(		' id="add_to_shopping_list"');
				ret.push(		' class="'+((isOnShopping_list==true)?' active':'')+'"');
				// ret.push(		' '+START_EV+'="pages.shopping_list.button('+r['id']+');">');
				ret.push(		'>');
				ret.push(		'&nbsp;');
				ret.push(		'</a>');
				ret.push(	'</div>');

				ret.push('</div>'); // EO: 2. BLOCK DATA_CELLE
				// SET FAVORITE & SHOPPING_LIST
				ret.push('</div>'); // EO:DATA_BLOCK

				ret.push('</li>');

				// Zutaten ----------------------------------
				var ings_html = pages.recipes.ingredients( r );
				ret.push( ings_html );


				// Zubereitung ----------------------------------
				ret.push('<li class="title">');
				ret.push('<h3>');
				ret.push('Zubereitung für ');
				ret.push(r['@amount']+' ');
				ret.push(r['@unit']);
				ret.push('</h3>');
				ret.push('</li>');

				// ----------------------------------
				ret.push('<li>');
				ret.push('<div>');
				if (!_.isUndefined(r['preparation']['$'])) {
					ret.push(r['preparation']['$']);
				} else {
					ret.push(r['preparation']);
				}
				ret.push('</div>');
				ret.push('</li>');


				// Nährwerte ----------------------------------
				ret.push('<li class="title">');
				ret.push('<h3>');
				ret.push('Nährwerte pro '+r['nutritions']['@pro']);
				ret.push('</h3>');
				ret.push('</li>');
				// ----------------------------------
				ret.push('<li>');
				ret.push('<table border="0" cellspacing="0" cellpadding="0"><tbody>');
				ret.push('<tr>');
				ret.push('<td>Brennwert</td>');
				ret.push('<td>'+r['nutritions']['@kj']+' kJ / '+r['nutritions']['@kcal']+' kcal</td>');
				ret.push('</tr>');

				var nu = r['nutritions']['nutrition'];
				var nu_length = nu.length;
				for (var i = 0; i < nu_length; i++) {
					ret.push('<tr>');
					ret.push('<td>'+nu[i]['$']+'</td>');
					ret.push('<td>');
					ret.push(nu[i]['@amount']+' '+nu[i]['@unit']);
					if (nu[i]['sugar']) {
						// sugar on "Kohlenhydrate" nutrition
					}
					ret.push('</td>');
					ret.push('</tr>');
				}
				ret.push('</tbody></table>');
				ret.push('</li>');


				// Zubereitungsdauer ----------------------------------
				ret.push('<li class="title">');
				ret.push('<h3>');
				ret.push('Zubereitungsdauer (Arbeitsszeit): '+r['preparationtime']['$']+' '+r['preparationtime']['@unit']);
				ret.push('</h3>');
				ret.push('</li>');


				// Schwierigkeitsgrad ----------------------------------
				ret.push('<li class="title">');
				ret.push('<h3>');
				ret.push('Schwierigkeitsgrad: '+r['complexity']);
				ret.push('</h3>');
				ret.push('</li>');

				// QUELLE COPY RIGHT ----------------------------------
				if (_.isObject(r['copyright'])) {
					ret.push('<li class="title copyright">');
					ret.push('<h3>');
					ret.push('Quelle: ');
					ret.push('<a href="'+r['copyright']['content']['@link']+'" target="_blank">');
					ret.push('<img src="img/copyright/'+r['copyright']['content']['@img']+'" title="'+r['copyright']['content']['$']+'" class="copyright" />');
					ret.push('</h3>');
					ret.push('</li>');
				}

				// Favo ----------------------------------
				var favorites_check = pages.favorites.setButtons(r['id'], isOnFavorites);
				var active = (favorites_check['calc']=='minus') ? 'active' : '';
				var chkStars = (favorites_check['calc']=='minus') ? 0 : 1;
				ret.push('<li class="button">');
				ret.push('<a href="javascript:;"');
				ret.push(' id="favorites_btn"');
				ret.push(' class="'+active+'"');
				// ret.push(' onclick="pages.favorites.button('+r['id']+')">');
				ret.push(' on'+END_EV+'="pages.favorites.set_star('+chkStars+', '+r['id']+')">');
				ret.push(	'<span>'+favorites_check['txt']+'</span>');
				ret.push('</a>');
				ret.push('</li>');


				// Shopping ----------------------------------
				var shopping_list_check = pages.shopping_list.setButtons(r['id'], isOnShopping_list);
				var active = (shopping_list_check['calc']=='minus') ? 'active' : '';

				// alert(JSON.stringify(shopping_list_check))

				ret.push('<li class="button">');
				ret.push('<a href="javascript:');
				// ret.push('pages.shopping_list.button('+r['id']+')');
				ret.push(';"');
				ret.push(' id="shopping_list_btn"');
				ret.push(' class="'+active+'"');
				ret.push(' on'+END_EV+'="pages.shopping_list.button('+r['id']+')"');
				ret.push('>');
				ret.push(	'<span>'+shopping_list_check['txt']+'</span>');
				ret.push('</a>');
				ret.push('</li>');

				var html='<ul id="content_inner" class="detail">'+ret.join('')+'<li>&nbsp;</li></ul>';

				// $('#code').text(html)

				$item = $('#img_recipes_large');
				$item.css({
					'background-image':"url('img/recipes_large/"+r['image']['$']+"')",
				});
				$item.data('platform', r['image']['@platform']);
				$item.data('licence', r['image']['@licence']);
				$item.data('imageid', r['image']['@imageid']);
				
				console.log('pages['+this.name+'].detail');
				console.log('r[image]', r['image']);

				return html;
			}; // EO: return recipes detail
		}(), // EO: recipes detail

		image: {
			show: function(id){

				return function(id){
					var r = pages.recipes.find(id)
					var title = r['title'];
					var image_path = 'img/recipes_large/'+r['image']['$'];

					var img_recipes_large = $('#img_recipes_large');
					
					img_recipes_large.data('platform', r['image']['@platform']);
					img_recipes_large.data('licence', r['image']['@licence']);
					img_recipes_large.data('imageid', r['image']['@imageid']);
					
					$('.image_data').html('Foto: '+r['image']['@licence']+' @ '+r['image']['@platform']);

					img_recipes_large.css({
						'width':$('#content_wrapper').width(),
						'height':$('#content_wrapper').height(),
                        'opacity':1
					})
					.bind(MOVE_EV, function (e) {
						e.preventDefault();
						e.stopPropagation();
					})
					.bind('click', function (e) {
						pages.recipes.image.hide();
					})
					.addClass('active');
				}; // EO: return recipes image show
			}(), // EO: recipes image show

			hide: function(){

				return function(){
					var img_recipes_large = $('#img_recipes_large');
                    img_recipes_large.unbind('click');
					img_recipes_large.removeClass('active');
					_.delay(function(){
						img_recipes_large.css({
							'width':0,
							'height':0
						});
					}, 600);
				}; // EO: return recipes image hide
			}(), // EO: recipes image hide

		},

		factor: function(r){

			return function(r){
				var id = (_.isObject(r))?r['id']:r;
				var data = pages['shopping_list'].where();
				var item = _.findWhere(data, {id:id});
				var factor = (_.isUndefined(item)) ? 1: item['shopping_list_factor'];

				return factor;
			}; // EO: return recipes factor
		}(), // EO: recipes factor

		ingredients: function(r, type){

			return function(r, type){

				var ing = r['ingredients']['ingredient'];
				var factor = pages.recipes.factor(r);

				var ret = new Array();
				var ret_txt = new Array();

				ret.push('<li class="r_'+r['id']+' title">');
				ret.push('<h3>');
				ret.push('Zutaten');
				ret.push('</h3>');
				ret.push('</li>');
				// ----------------------------------
				ret.push('<li class="r_'+r['id']+' ingredients">');
				ret.push('<table border="0" cellspacing="0" cellpadding="0"><tbody>');
				// var ing = r['ingredients']['ingredient'];
				var ing_length = ing.length;
				for (var i = 0; i < ing_length; i++) {
					ret.push('<tr>');

					var amount_int = parseFloat(ing[i]['@amount']);
					var amount_base = amount_int;
					var amount_output = amount_int * factor;
					amount_output = (amount_output>0)?amount_output:'';
					var unit = ing[i]['@unit'];
					var txt = ing[i]['$'];

					ret.push('<td>');
					ret.push('<input type="hidden" class="a_init" value="'+amount_base+'" />');
					ret.push('<span class="a_data">');
					ret.push(amount_output);
					ret_txt.push(amount_output);
					ret.push('</span> ');
					ret.push(unit);
					ret_txt.push(unit);
					ret.push('<td>');

					//ret.push('<td>'+ing[i]['@amount']+' '+ing[i]['@unit']+'</td>');
					ret.push('<td>');
					ret.push(txt);
					ret_txt.push(' '+txt+'\n');
					ret.push('</td>');
					ret.push('</tr>');
				}
				ret.push('</tbody></table>');
				ret.push('</li>');

				var html=ret.join('');
				var txt=ret_txt.join('');

				//return '<pre>'+txt+'</pre>';
				if (type=='text') {
					return txt;
				} else {
					return html;
				}
			}; // EO: return recipes ingredients

		}(), // EO: recipes ingredients

		find: function(rid){

			return function(rid){
				var element = _.findWhere(recipes.items, {id: parseInt(rid)});
				console.log(['recipes.find('+rid+')', element]);
				return element;
			}; // EO: return recipes find
		}(), // EO: recipes find

		category: function(cat){

			return function(cat){
				var elements = _.where(recipes.items, {category: cat});
				console.log(['recipes.category('+cat+')', elements]);
				return elements;
			}; // EO: return recipes category
		}(), // EO: recipes category

		preloadImages: function(){

			return function(){
				if(app.db.get('init') === true){
					var preloader_style_items = [];
					var images_path = 'img/';
					var layout_images = {
						'root' : [
							'title_vigo_logo@2x.png',
							'icons-36-white.png',
							'toolbar.png',
							'toolbar_act.png',
							'backButton@2x.png',
							'index_reload@2x.png'
						],
						'recipes_detail_icons' : [
							'add_to_favorites.png',
							'add_to_favorites_on.png',
							'add_to_shopping_list.png',
							'add_to_shopping_list_on.png',
							'clear.png',
							'default.png',
							'favorites.png',
							'shopping_list.png'
						],
						'recipes_thumbs_icons' : [
							'clear.png',
							'favorites.png',
							'shopping_list.png'
						],
						'searchbar' : [
							'cat_recipes.png',
							'magnifying.png',
							'search_clear.png'
						],
					};
					var base_path = '';
					for(e in layout_images){
						base_path = (e !== 'root') ? e+'/' : '';
						for (var i = 0; i < layout_images[e].length; i++) {
							preloader_style_items.push('url("'+images_path+base_path+layout_images[e][i]+'")');
						}
					}
					var items = recipes.items;
					var items_length = items.length;
					var recipes_path = images_path+'recipes_thumbs/';
					for (var j = 0; j < items_length; j++) {
						preloader_style_items.push('url("'+recipes_path+items[j]['image']+'")');
					}
					// console.log(preloader_style_items);

					var preloader_style_output = '#preloader{background-image:'+preloader_style_items.join(',')+'}';
					// $('#preloader_style').text(preloader_style_output);
				}
			}; // EO: return recipes preloadImages
		}(), // EO: recipes preloadImages

	},

	// searchbar and search
	'searchbar': {
		'name':'searchbar',
		'toolbar':false,
		'content':'',
		q:'',
		c:'',
		'init':function(){
			return function(){
				var search_markup = pages.searchbar.markup();
				searchbar_container.innerHTML=search_markup;
			}; // EO: return searchbar init
		}(), // EO: searchbar init

		'markup': function(){

			return function(){
				var name			= this.name;

				var ret = new Array();
				ret.push('<form id="searchform_form"');
				ret.push(	' class="tbar form"');
				ret.push(	' method="post"');
				ret.push(	' action="javascript:;"');
				ret.push(	' onsubmit="pages.searchbar.search();">');
				ret.push(	'<ul>');
				ret.push(		'<li class="search_wrapper"');
				//ret.push(			' onclick="$(\'#search\').click()"');
				ret.push(			'>');
				ret.push(			'<div class="tbar_wrapper">');
				ret.push(				'<a href="javascript:;"');
				ret.push(					' on'+END_EV+'="');
				ret.push(						'$(\'input#search\').val(\'\');');
				ret.push(						'$(\'a#search_clear\').removeClass(\'activated\');');
				ret.push(						'$(\'form#searchform_form\').submit();');
				ret.push(						'"');
				ret.push(					' id="search_clear">x</a>');
				ret.push(				'<input id="search"');
				ret.push(					' name="search"');
				ret.push(					' type="text"');
				ret.push(					' placeholder="Suche..."');
				ret.push(					' value=""');
				ret.push(					' size="30"');
				// ret.push(					' x-webkit-speech=""');
				// ret.push(					' oninput="$(\'form#searchform_form\').submit();"');
				ret.push(					' oninput="');
				ret.push(						'$(\'a#search_clear\').addClass(\'activated\');');
				ret.push(						'if(this.value==\'\'){');
				ret.push(							'$(\'a#search_clear\').removeClass(\'activated\');');
				ret.push(						'};');
				ret.push(						'"');
				ret.push(					'>');
				ret.push(			'</div>');
				ret.push(		'</li>');
				ret.push(		'<li class="category_wrapper"');
				//ret.push(			' onclick="$(\'#category\').click()"');
				ret.push(			'>');
				ret.push(			'<div class="tbar_wrapper">');
				ret.push(				'<div class="style_select"');
				ret.push(					' on'+END_EV+'="app.form.select.focus(\'category\', event)"');
				ret.push(					'>');

				ret.push(					'<select name="category"');
				ret.push(						' id="category"');
				ret.push(						' onchange="$(\'form#searchform_form\').submit();"');
				//ret.push(						' ontouchstart="event.stopPropagation();"');
				//ret.push(						' onclick="this.focus();this.select()"');
				ret.push(						' on'+END_EV+'="app.form.select.focus(this.id, event)"');
				ret.push(						'>');

				ret.push(							'<option value="">Alle Kategorien</option>');
				for (cat in recipes.categories) {
					//var select=(this.c!='' && this.c==cat)?' selected="selected"':'';
					ret.push(						'<option value="'+cat+'">'+cat+'</option>');
				}
				ret.push(					'</select>');

				ret.push(				'</div>');
				ret.push(			'</div>');
				ret.push(		'</li>');
				ret.push(	'</ul>');
				ret.push('</form>');

				var html=ret.join('');
				return html;
			}; // EO: return searchbar markup

		}(), // EO: searchbar markup

		'search': function(){

			return function(){
				var result_items = new Array();

				var search = $('input#search');
				var category = $('select#category');
				this.q = q = search.val();
				this.c = c = category.val();

				var items = recipes.items;
				if (q!='' && q.length>=2) {
					for (var i = 0; i < items.length; i++) {
						var item = items[i];
						var value = JSON.stringify(item);

						q = q.toLowerCase();
						value = value.toLowerCase();
						var result = value.match(q);

						if (_.isArray(result) && result.length>0) {
							result_items.push(item)
						}
						// alert(result.length)
					}
					// alert( JSON.stringify(result_items) );
				} else {
					result_items = recipes.items; // if no search query added
				}

				// search only in category if it's selected
				if (c!='') {
					result_items = (result_items.length>0)?result_items:recipes.items;
					result_items = _.where(result_items, {category: c});
				}
				//console.log(JSON.stringify(result_items));

				// if(result_items.length>0){
				// 	app.notification.alert('Kein Rezepte gefunden');
				// }

				var name		= 'recipes';
				var html		= pages.recipes.list(result_items, name);
				pages['content'].load(html, function(){
					search.trigger('blur');
					category.trigger('blur');

					app.scroller.refresh();
					app.scroller.scrollTo(0, 0, 250);
				});
			}; // EO: return searchbar search
		}(), // EO: searchbar search

	},

	// BADGE List template
	'template': {
		'name':'template',
		'btn_val':'Templateliste',
		'toolbar':false,
		'title':'Template',
		'content':'',
		'items':[],
		'init':function(){

			return function(){

				console.log(this.name+'.init', arguments);

				var name		= this.name;
				var elements_ids = pages[name].where();
				var elements = new Array();
				for (var i = 0; i < elements_ids.length; i++) {

					console.log(['for pages['+name+'].init elements_ids[i]->', elements_ids[i]['id']])

					var item = _.findWhere(recipes.items, {id:elements_ids[i]['id']});
					elements.push(item);
				}
				console.log(['pages['+name+'].init elements->', elements])

				var html		= pages.recipes.list(elements, name);
				pages['content'].load(html);

				console.info(this.name+'.init');
			} // EO: return template init

		}(), // EO: template init

		'page':function(pid){

			console.log(this.name+'.page', arguments);

			pages.recipes.page(pid);
			console.info(this.name+'.page('+pid+')');
		},

		'where': function(){
			return function(){

				console.log(this.name+'.where', arguments);

				var name=this.name;
				var items = app.db.get(name);

				console.log([name+'.where items->', items])
				return _.uniq(items);
			}; // EO: return template where
		}(), // EO: template where

		'set': function(data){

			return function(data){
				var name = this.name;
				//recipes[name] = data;
				//pages[name]['items'] = data;
				// setTimeout(function(){
					app.db.set(name, data);
				// }, 0);
			};
		}(),

		'isActive':function(id, data){
			return function(id, data){

				console.log(this.name+'.isActive', arguments);

				var name=this.name;
				var data = (!_.isUndefined(data))?data:pages[name].where();
				var check_status = _.findWhere(data, {id:id});

				// console.log(name+' check_status='+(!_.isUndefined(check_status)))

				return [((!_.isUndefined(check_status))?true:false), check_status];
			}; // EO: return template isActive
		}(),  // EO: template isActive

		'button': function (id, delete_flag) {

			return function(id, delete_flag){

				console.log('--BUTTON--');
				console.log(this.name+'.button', arguments);

				app.spinner.start(function(){

					$('#content_inner').toggleClass('toggler');

				}, 1000);

				var name		= this.name;
				var stored_data = pages[name].where();
				//var stored_data = pages[name]['items'];

				var chkObj = pages[name].isActive(id, stored_data);
				var isOnObj = chkObj[0];

				var status	= ( (name=='favorites' && isOnObj && (chkObj[1]==0 || chkObj[1]==''))
									|| (name=='shopping_list' && isOnObj) ) ? false : true;

console.log('this.name = '+this.name);

				console.log(status)

				if (delete_flag) {
					// DELETE
					var recipe = pages.recipes.find(id);
					app.notification.confirm(
						'Möchten Sie das Rezept \n"'+recipe['title']+'"\nvon der '+this.btn_val+' entfernen?',
						function(buttonIndex){
							/*
							 * Return Parameter: buttonIndex
							 * Abbrechen = 1
							 * Ok = 2
							 */
							// alert('buttonIndex='+buttonIndex);
							$('.delete_btn').blur();
							if (buttonIndex==2) {
								var data = pages[name].setItem(stored_data, id, false); // delete status
								$('li.r_'+id).remove();
								$('li.r_'+id+'.title').remove();
								$('li.r_'+id+'.ingredients').remove();

								// $('li.r_'+id).remove();


								pages[name].set(data);
								// app.db.set(name, data);
								pages[name].setButtons(id, status);
								pages[name].badge(data);
							}
						}
					);
				} else {
					// SAVE
					var data = pages[name].setItem(stored_data, id, status);
					pages[name].set(data);
					//app.db.set(name, data);
					pages[name].setButtons(id, status);
					pages[name].badge(data);
				}

console.log('this.name = '+this.name);

				//return false;

			}; // EO: return template button

		}(),  // EO: template button

		'setItem': function(data, id, status){

			return function(data, id, status){

				console.log(this.name+'.setItem', arguments);

				var name = this.name;
				var data = (data)?data:app.db.get(name);

				var item_data = {};
				item_data['id'] = id;
				if (name=='shopping_list') {
					item_data['shopping_list_factor'] = $('#factor').val();
				}
				if (name=='favorites') {
					item_data['favorites_stars'] = $('#favorites_stars').val();
				}

				if (status) {
					data.push(item_data);
				} else {
					for (var i = 0; i < data.length; i++) {
						if(data[i]['id']==id){
							delete data[i];
						}
					}
				}
				data = _.uniq(data);

				return data;
			}; // EO: return template setItem
		}(), // EO: template setItem

		'setButtons': function (id, status, data) {

			return function (id, status, data) {

				console.log(this.name+'.setButtons', arguments);

				var name		= this.name;
				var check	= {}

				// var button = $('.button .'+name);
				var button = $('#'+name+'_btn');
				var recipes_status_icon = $('#recipes_status_icon');
				var add_to_favorites = $('#add_to_favorites');
				var add_to_shopping_list = $('#add_to_shopping_list');

				var chkFavorites = pages['favorites'].isActive(id, data);
				// alert(JSON.stringify(chkFavorites))

				var chkShopping_list = pages['shopping_list'].isActive(id);
				var isOnFavorites = chkFavorites[0];
				var favorites_stars = (isOnFavorites)?chkFavorites[1]['favorites_stars']:0;
				var isOnShopping_list = chkShopping_list[0];

				if (
					(name=='shopping_list' && isOnShopping_list==true)
					||
					(name=='favorites' && isOnFavorites==true && favorites_stars>0)
				) {
					check['txt'] = 'Von '+this.btn_val+' entfernen';
					check['calc']= 'minus';
					button.addClass('active');
					if (name=='shopping_list') {
						click_state = 'pages.shopping_list.button('+id+')';
					}
					else if (name=='favorites'){
						click_state = 'pages.favorites.set_star(0, '+id+')';
					}
					button.attr('on'+END_EV, click_state);
				} else {
					check['txt'] = 'Zur '+this.btn_val+' hinzufügen';
					check['calc']= 'plus';
					button.removeClass('active');
					if (name=='shopping_list') {
						click_state = 'pages.shopping_list.button('+id+')';
					}
					else if (name=='favorites'){
						click_state = 'pages.favorites.set_star(1, '+id+')';
					}
					button.attr('on'+END_EV, click_state);
				}
				button.html('<span>'+check['txt']+'</span>');

				recipes_status_icon.attr('class', '');
				if (isOnShopping_list==true) {
					recipes_status_icon.addClass('shopping_list');
					// recipes_status_icon.attr('class', 'shopping_list');
				}
				else if (isOnFavorites==true && favorites_stars>0) {
					// recipes_status_icon.addClass('favorites');
					// recipes_status_icon.attr('class', 'favorites');
					// var favorites_classes = recipes_status_icon.attr('class').split(' ');
					// for (var i = 0; i < favorites_classes.length; i++) {
					// 	if(/^favorites_/.test(favorites_classes[i])){
					// 		recipes_status_icon.addClass('favorites_'+favorites_stars.toString().replace(/\./, '_'));
					// 	}
					// }
					recipes_status_icon.attr('class', 'favorites favorites_'+favorites_stars.toString().replace(/\./, '_'));

					// favorites_
					// alert(JSON.stringify(favorites_stars))

				}
				else {
					//recipes_status_icon.removeClass('favorites').removeClass('shopping_list');
					recipes_status_icon.attr('class', '');
				}

				if (
					isOnFavorites==true
					&& (chkFavorites && chkFavorites[0]==true && favorites_stars>0)) {
					add_to_favorites.addClass('active');
					// add_to_favorites.attr('class', 'active');
				} else {
					add_to_favorites.removeClass('active');
					// add_to_favorites.attr('class', '');
				}

				if (isOnShopping_list==true) {
					add_to_shopping_list.addClass('active');
					// add_to_shopping_list.attr('class', 'active');
				} else {
					add_to_shopping_list.removeClass('active');
					// add_to_shopping_list.attr('class', '');
				}
				return check;
			}; // EO: return template setButtons

		}(), // EO: template setButtons

		'badge': function (stored_data) {

			return function (stored_data) {

				console.log(this.name+'.badge', arguments);

				var name			= this.name;
				var elements	= (!_.isUndefined(stored_data))?stored_data:pages[name].where();
				var elements_length = elements.length;
				app.toolbar.badge(name, elements_length);

				// app.db.set('recipes', recipes); // resave all
				//app.db.set(name, recipes); // resave all

				if (elements_length==0) {
					$('.no_entry').addClass('activate');
					$('#mail_btn').parent().remove();
				} else {
					$('.no_entry').removeClass('activate');
				}

				return elements_length;
			}; // EO: return template badge

		}(), // EO: template badge

	},

	'favorites': {
		'name':'favorites',
		'btn_val':'Favoritenliste',
		'toolbar':true,
		'title':'Favoriten',
		'content':'',

		// 'where': function(){
		// 	return function(){
		// 		var items = app.db.get('favorites');
		// 		console.log(['favorites.where items->', items])
		// 		return _.uniq(items);
		// 		// return _.uniq(_.where(recipes_items, {'favorites': true}));
		// 	}; // EO: return favorites where
		// }(), // EO: favorites where

		'showStars': function(){
			return function(){
				$stars = $('div.stars');
				// $stars.css({'display':'block'});
            // $stars.animate({'opacity':1.0}, 400, 'ease-in', function(){
            //    $stars.css({'display':'block'});
            // });
            $stars.fadeIn("slow", function () {
               $stars.css({'display':'block'});
            });
				pages.favorites.hideStars(9000);
			}
		}(),
		'hideStars': function(){
			return function(time){
				var time = (time)?time:10000;
				window.showStarsInt=clearInterval(window.showStarsInt);
				window.showStarsInt = setInterval(function(){
					$stars = $('div.stars');
               // $stars.animate({opacity:0}, 400, 'ease-out', function(){
               //    $stars.css({'display':'none'});
               // });
               $stars.fadeOut("slow", function () {
                  $stars.css({'display':'none'});
               });
					window.showStarsInt=clearInterval(window.showStarsInt);
				}, time);
			}
		}(),

		'set_star': function(star, id){

			return function(star, id){
				var name = this.name;
				console.log('set_star(star='+star+', id='+id+')');

				pages.favorites.hideStars(400);

				$('#favorites_stars').val(star)
				var favorites_stars = star;

				// look to tems
				var data = pages['favorites'].where();

				// check before delete item
				var chkFavorites = pages['favorites'].isActive(id, data);
				var isOnFavorites = chkFavorites[0];
				//var chkStars = parseFloat(chkFavorites[1]['favorites_stars']);

				data = pages['favorites'].setItem(data, id, false); // delete status

				var item_data = {};
				item_data['id'] = id;
				item_data['favorites_stars'] = favorites_stars;

				//alert('set_star(star='+star+', id='+id+')');
				if (star > 0) {
					data.push(item_data);  // add it to the data array
					pages['favorites'].setButtons(id, false, data);
				} else {
					pages['favorites'].setButtons(id, true, data);
				}
				pages['favorites'].set(data);
				pages['favorites'].badge(data);


				var random_color = app.helper.randomColor();
				// if (isOnFavorites) {
					var star_index = 0;
					$('.star').each(function (i,e) {
						star_index++;
						$this = $(this);
						var star_item = star_index/2;
						var is_act = (star_item<=favorites_stars) ? true:false;
						if (is_act!=false) {
							$this.addClass('act');
						} else {
							$this.removeClass('act');
						}
					});
				// }

			}
		}(),
	},

	'shopping_list': {
		'name':'shopping_list',
		'btn_val':'Einkaufsliste',
		'toolbar':true,
		'title':'Einkaufsliste',
		'content':'',

		// 'where': function(){
		//
		// 	return function(){
		// 		var items = app.db.get('shopping_list');
		// 		console.log(['shopping_list.where items->', items])
		// 		return _.uniq(items);
		// 	}; // EO: return where
		//
		// }(), // EO: where

		'set_qty': function(dir, id){

			return function(dir, id){
				var name = this.name;

				// alert(dir)
				var item		= pages['shopping_list'].where();
				var recipe_amount=parseInt($('#recipe_amount').val()); // initialer amount wert
				var factor=parseInt($('#factor').val()); // faktor
				factor=(isNaN(factor))?0:factor;
				var cur_qty_factor = 1;

				if (dir=='up') {
					cur_qty_factor = factor+1;
					cur_qty_factor = (cur_qty_factor>=1000)? 1000: cur_qty_factor;
				} else {
					cur_qty_factor = factor-1;
					cur_qty_factor = (cur_qty_factor<=1)? 1: cur_qty_factor;
				}

				$('#factor').val(cur_qty_factor);
				var cur= cur_qty_factor * recipe_amount;

				$('#qty').val(cur);
				$('#qty_txt').text(cur);
				// $('#qty_txt2').text(cur);

				$('.a_init').each(function(i, e){
					var calc_val = Math.floor($(e).val() * cur_qty_factor * 100)/100;
					if (calc_val == 0 || _.isNaN(calc_val)) {
						$(e).next('span.a_data').text( '' );
					}else{
						$(e).next('span.a_data').text( calc_val );
					}
				});

				var shopping_list_factor = (pages.shopping_list.isActive(id)) ? cur_qty_factor: 1;
				var data = pages[name].where();

				var isActiveRecipe = pages[name].isActive(id, data);

				if ( isActiveRecipe[0] ) {
					data = pages[name].setItem(data, id, false); // delete status

					var item_data = {};
					item_data['id'] = id;
					if (name=='shopping_list') {
						item_data['shopping_list_factor'] = shopping_list_factor;
					}
					data.push(item_data);  // add it to the data array

					pages[name].set(data);
					// app.db.set('shopping_list', data);
				}

				setTimeout(function(){
					$('.plus').blur();
					$('.minus').blur();
				}, 750);

			}; // EO: return set_qty

		}(), // EO: set_qty

		'mail': function(items){

			return function (items) {
				var name		= this.name;

				if (!items) {
					var item_ids = pages[name].where();
					var items = new Array();
					for (var i = 0; i < item_ids.length; i++) {
						var item = _.findWhere(recipes.items, {id:item_ids[i]['id']});
						items.push(item);
					}
				}

				var items_length = items.length;
				var ret = new Array();

				var ings_summary = new Array();
				var ings_summary_ids = new Array();
				var passed_count=0;

				for (var i = 0; i < items_length; i++) {

					var r = items[i];

					// getBase64FromImageUrl('img/recipes_thumbs_mail/'+r['image'], function(d){

						var factor = pages.recipes.factor(r);
						var act_amount = parseInt(r['@amount']) * factor;

						ret.push(r['title']);
						var title_length = r['title'].length;
						var line = '';
						for (var j = 0; j < title_length; j++) {
							line+='=';
						}
						ret.push(line);

						// BASE64 Image
						// ret.push('<img src="'+d+'" />');
						// EO: BASE64 Image

						ret.push('\n== Zutaten');
						ret.push('=== Rezept für '+act_amount+' '+r['@unit']+'\n');

						// Zutaten ----------------------------------
						var ings_txt = pages.recipes.ingredients( r, 'text');
						ret.push( ings_txt );

						ings_summary.push(r['ingredients']['ingredient'])
						ings_summary_ids.push( r['id'] );

						// Zubereitung ----------------------------------
						ret.push('== Zubereitung für '+r['@amount']+' '+r['@unit']+'\n');
						// ----------------------------------
						var preparation='';
						if (!_.isUndefined(r['preparation']['$'])) {
							preparation = r['preparation']['$'];
						} else {
							preparation = r['preparation'];
						}
						// ret.push(preparation.replace(/[\\n|\\r\\n]/, ' '));
						preparation = escape(preparation).replace(/%0A/g," ");
						preparation = unescape(preparation); //.replace(/(\s?){2,*}(\w)/g," $2");;
						ret.push(preparation);
						ret.push('\n');
						ret.push('-------------------------------------------\n');
						ret.push('\n');

						// passed_count++;
						// if (passed_count==i) {
							// do somehting
						// }
						// })

				} // EO: for


				// MAIL: INGREDIENTS SUMMARY
				var ings_summary_ret = new Array();
				ings_summary_ret.push('-------------------------------------------');
				ings_summary_ret.push('Ihre Einkaufsliste');
				ings_summary_ret.push('-------------------------------------------');
				ings_summary_ret.push('');

				for (var i = 0; i < ings_summary.length; i++) {
					var item = ings_summary[i];
					var item_length = item.length;

					var factor = pages.recipes.factor( parseInt(ings_summary_ids[i]) );
					console.log('factor='+factor)

					for (var j = 0; j < item_length; j++) {
						ings_summary[i][j]['@amount'] = parseFloat(item[j]['@amount']) * factor;
					}
				}
				ings_summary = _.flatten(ings_summary, true);


				var ings_summary_length = ings_summary.length;
				var ings_summary_collection = new Object();
				var ings_summary_collection_keys = new Array();

				for (var i = 0; i < ings_summary_length; i++) {
					var obj = ings_summary[i];
					var key = (obj['@unit']+' '+obj['$']).toLowerCase();

					if (_.indexOf(ings_summary_collection_keys, key)==-1) {

						console.log(key+' = nicht gefunden ')

						ings_summary_collection_keys.push(key);
						ings_summary_collection[ key ] = {
							'title': obj['$'],
							'amount': parseFloat(obj['@amount']),
							'unit': obj['@unit'],
						}
					} else {

						console.dir(key+' = gefunden ')

						ings_summary_collection[ key ] = {
							'title': obj['$'],
							'amount': parseFloat(ings_summary_collection[ key ]['amount']) + parseFloat(obj['@amount']),
							'unit': ings_summary_collection[ key ]['unit'],
						}
					}
				}

				ings_summary_collection_keys.sort();
				var ings_summary_collection_keys_length = ings_summary_collection_keys.length;

				for (var i = 0; i < ings_summary_collection_keys_length; i++) {
					var key = ings_summary_collection_keys[i];

					var ing = ings_summary_collection[ key ];
					var title = ing['title'];
					var amount = (_.isNaN(ing['amount'])?'':ing['amount']);
					var unit = ing['unit'];

					ings_summary_ret.push(amount+''+unit+' '+title);

					console.log('ing -> '+key+'='+JSON.stringify(ing))
				}

				ings_summary_ret.push('');
				ings_summary_ret.push('-------------------------------------------');
				ings_summary_ret.push('-------------------------------------------');
				ings_summary_ret.push('\n');
				ings_summary_ret.push('\n');
				ings_summary_ret.push('-------------------------------------------');
				ings_summary_ret.push('Die Rezepte aus Ihrer Einkaufsliste');
				ings_summary_ret.push('-------------------------------------------');
				ings_summary_ret.push('\n');

				var ings_summary_ret = ings_summary_ret.join('\n');

				console.dir( ings_summary_collection_keys )
				// console.log('ings_summary='+JSON.stringify(ings_summary))

				// EO: MAIL: INGREDIENTS SUMMARY

				var body = ings_summary_ret + ret.join('\n');
				var subject = 'Einkaufsliste: AOK glutenfreie Rezepte';
				var mailto_link='mailto:?subject='+encodeURIComponent(subject)+'&body='+encodeURIComponent(body);


            // alert('mailto_link == '+mailto_link);

            // window.open(mailto_link);

            var ref = window.open(mailto_link, '_system', 'location=yes,hidden=yes');
            window.setTimeout(function (ref) {
               ref.close();
            }, 1000, ref);


				// window.location.href = mailto_link;
				document.location = mailto_link;
			} // EO:return mail

		}(), // EO:mail


	}, // EO: shopping_list


		'privacy': {
			'name':'privacy',
			'toolbar':false,
			'title':'Datenschutzerklärung',
			'content':'',
			'init':function(){

				return function(){
					var ret = new Array();

					ret.push(pages.imprint.menu('privacy'));
					ret.push('<ul id="content_inner" class="privacy">');
					ret.push('<li>');
					ret.push('<h2>');
					ret.push(	'Datenschutzerklärung');
					ret.push('</h2>');
					ret.push('<p>');
					ret.push('Der AOK ist der Schutz Ihrer persönlichen Daten besonders wichtig. Wir möchten Sie daher darüber informieren welche Daten durch die Verwendung der App „AOK glutenfreie Rezepte“ von uns erhoben und verarbeitet werden.');
					ret.push('</p>');
					ret.push('<p>');
					ret.push('Die App „AOK glutenfreie Rezepte“ erhebt keine personenbezogenen Daten. Eine solche Erhebung und Verarbeitung durch die AOK Rheinland/Hamburg findet nur dann statt, wenn Sie uns diese Daten direkt mitteilen.');
                    ret.push('</p>');
					ret.push('<p>');
                    ret.push('Darüber hinaus können durch die Nutzung dieser App im Rahmen der von Ihnen erteilten Einwilligung in die Nutzungsbedingungen Ihres Geräteanbieters, Service-Providers oder Appstore-Betreibers Daten erhoben, verarbeitet oder genutzt werden. Für diese Art der Datenerhebung,- verarbeitung und Nutzung ist die AOK Rheinland/Hamburg nicht verantwortlich.');
					ret.push('</p>');
					// ret.push('<p>');
					// ret.push('Selbst verständlich können Sie uns bei Fragen zu dieser Datenschutzerklärung und Ihren Datenschutzrechten und zu den Haftungsbeschränkung über <a href="mailto:vigo@rh.aok.de?subject:AOKglutenfrei%20Haftungsbeschränkung">vigo@rh.aok.de</a> kontaktieren.');
					// ret.push('</p>');

                    // ret.push('<p>');
                    // ret.push('Der AOK ist der Schutz Ihrer persönlichen Daten besonders wichtig. Wir möchten Sie daher darüber informieren welche Daten durch die Verwendung der App „AOK glutenfreie Rezepte“ von uns erhoben und verarbeitet werden.');
                    // ret.push('</p>');
                    // ret.push('<h3>');
                    // ret.push('Datenerhebung');
                    // ret.push('</h3>');
                    // ret.push('<p>');
                    // ret.push('Die App „AOK glutenfreie Rezepte“ erhebt keine personenbezogenen Daten. Eine solche Erhebung und Verarbeitung durch die AOK Rheinland/Hamburg findet nur dann statt, wenn Sie uns diese Daten direkt mitteilen.');
                    // ret.push('</p>');
                    // ret.push('<h3>');
                    // ret.push('Datensicherheit');
                    // ret.push('</h3>');
                    // ret.push('<p>');
                    // ret.push('Durch die Nutzung dieser App können im Rahmen der von Ihnen erteilten Einwilligung in die Nutzungsbedingungen Ihres Geräteanbieters, Service-Providers oder Store-Betreibers Daten erhoben, verarbeitet oder genutzt werden. Für diese Art der Datenerhebung, -verarbeitung und -nutzung ist der Anbieter nicht verantwortlich.');
                    // ret.push('</p>');
                    // ret.push('<p>');
                    // ret.push('Zur Sicherung der von Ihnen eingegebenen Daten empfehlen wir, das Smartphone mit einer Zugangssperre abzusichern.');
                    // ret.push('</p>');
                    // ret.push('<h3>');
                    // ret.push('Datenlöschung');
                    // ret.push('</h3>');
                    // ret.push('<p>');
                    // ret.push('Sie selbst bestimmen über Ihre Daten in Ihrer App. Wenn Sie die „AOK Glutenfreie Rezepte“ von Ihrem Smartphone löschen, werden auch alle zugehörigen Daten endgültig gelöscht.');
                    // ret.push('</p>');
                    // ret.push('<h3>');
                    // ret.push('Datennutzung außerhalb der App');
                    // ret.push('</p>');
                    // ret.push('</h3>');
                    // ret.push('<p>');
                    // ret.push('Eine Datennutzung außerhalb der App erfolgt nicht.');
                    // ret.push('</p>');
                    // ret.push('<p>');
                    // ret.push('Verantwortliche Stelle im Sinne des Bundesdatenschutzgesetzes ist:<br />');
                    // ret.push('AOK Rheinland/Hamburg – Die Gesundheitskasse<br />' +
						// 'Kasernenstraße 61 <br />' +
						// '40213 Düsseldorf<br />' +
						// 'Telefon: 0211 8791-0<br />' +
						// 'Telefax: 0211 8791-1125<br />' +
						// 'E-Mail:<a href="mailto:datenschutz@rh.aok.de">datenschutz@rh.aok.de</a>.');
                    // ret.push('</p>');
                    // ret.push('<h3>');
                    // ret.push('Betroffenenrechte');
                    // ret.push('</h3>');
                    // ret.push('<p>');
                    // ret.push('Nach der DSGVO §15-20 können Sie Ihr Auskunftsrecht, Recht auf Löschung und Sperrrecht wahrnehmen. Bitte wenden Sie sich dafür an den im Impressum angegebenen Kontakt oder an die oben angegebene verantwortliche Stelle im Sinne des Bundesdatenschutzgesetzes. Wir weisen Sie daraufhin, dass die App keine personenbezogenen Daten erhebt.');
                    // ret.push('</p>');
                    // ret.push('<h3>');
                    // ret.push('Tracking');
                    // ret.push('</h3>');
                    // ret.push('<p>');
                    // ret.push('Es erfolgt kein Tracking seitens der AOK. Wir weisen Sie jedoch daraufhin, dass durch die Installation der App ein Tracking durch den jeweiligen App-Store (Google Playstore für Android und Apple AppStore für iOS) erfolgt. Die Plattformbetreiber erheben anonymisierte Daten zur Verbreitung und Nutzung der App. Wir haben keinen Einfluss auf diese Erhebung. Verantwortliche Stelle ist der App-Store-Betreiber. Mehr erfahren Sie im jeweiligen Impressum.');
                    // ret.push('</p>');

                    if(platform!='webapp'){
                        url = "cordova.InAppBrowser.open('http://aok.de/rh/datenschutzrechte', '_blank', 'location=no,closebuttoncaption=Schliessen');"
                    } else {
                        url = 				"window.open('http://aok.de/rh/datenschutzrechte', '_blank', 'location=no,closebuttoncaption=Schliessen');"
                    }

                    ret.push('Allgemeine Informationen zur Datenverarbeitung und zu Ihren Rechten finden Sie unter <a href="javascript:void(0);" onclick="' + url + '">aok.de/rh/datenschutzrechte</a>. Bei Fragen wenden Sie sich an die AOK Rheinland/Hamburg, Kasernenstrasse 61, 40213 Düsseldorf, oder unseren Datenschutzbeauftragten unter <a href="mailto:datenschutz@rh.aok.de">datenschutz@rh.aok.de</a>.');

                    ret.push('');
					ret.push('</li>');

                    ret.push('<li>');
                    ret.push('<p>');
                    ret.push('<div on'+END_EV+'="app.reset.reload()">');
                    ret.push(	'<small>v'+app.version+'</small>');
                    ret.push('</div>');
                    ret.push('</p><p>&nbsp;</p><p>&nbsp;</p>');
                    ret.push('</li>');

					ret.push('</ul>');

					var html		= ret.join('');
					pages['content'].load(html);

					app.titlebar.left_nav('back');
					console.info('privacy.init');
				}; // EO: return privacy init

			}()  // EO: return privacy init
		},

        'disclaimer': {
			'name':'disclaimer',
			'toolbar':false,
			'title':'Haftungsbeschränkung',
			'content':'',
			'init':function(){

				return function(){
					var ret = new Array();

					ret.push(pages.imprint.menu('disclaimer'));
					ret.push('<ul id="content_inner" class="disclaimer">');
					ret.push('<li>');
					ret.push('<h2>');
					ret.push(	'Haftungsbeschränkung');
					ret.push('</h2>');
					ret.push('<p>');
					ret.push('Die AOK Rheinland/Hamburg übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt. ');
					ret.push('</p>');
					ret.push('<p>');
					ret.push('Die AOK haftet nicht dafür, dass Internetseiten nicht erreichbar und Dateien technisch nicht in Ordnung sind.');
					ret.push('</p>');
					ret.push('<p>');
					ret.push('Das Informationsangebot der AOK Rheinland/Hamburg enthält Querverweise ("Links") zu anderen Angeboten. Die Weiterleitung zu einem verlinkten Angebot eines anderen Diensteanbieters erkennen Sie grundsätzlich daran, dass im Browser ein neues Fenster geöffnet wird, in dem der entsprechende externe Inhalt angezeigt wird. Die externen ("fremden") Inhalte wurden beim Setzen des Links daraufhin geprüft, ob diese zivilrechtlich oder strafrechtlich gegen geltende Normen verstoßen. Es ist jedoch nicht auszuschließen, dass diese Inhalte im Nachhinein von den jeweiligen Anbietern verändert werden. Sollten Sie der Ansicht sein, dass verlinkte externe Seiten gegen geltendes Recht verstoßen oder sonst unangemessene Inhalte haben, so teilen Sie uns dies bitte mit. Wir werden Ihren Hinweis prüfen und den externen Link gegebenenfalls umgehend entfernen. Insoweit haften wir nicht für die Inhalte verlinkter externer Internetseiten oder Apps, ebenso wenig für deren Verfügbarkeit.');
					ret.push('</p>');
					// ret.push('<p>');
					// ret.push('Selbst verständlich können Sie uns bei Fragen zu dieser Datenschutzerklärung und Ihren Datenschutzrechten und zu den Haftungsbeschränkung über <a href="mailto:vigo@rh.aok.de?subject:AOKglutenfrei%20Haftungsbeschränkung">vigo@rh.aok.de</a> kontaktieren.');
					// ret.push('</p>');
					ret.push('');
					// ret.push('<p>');
					// ret.push('<div onclick="app.reset.reload()">');
					// ret.push(	'<small>v'+app.version+'</small>');
					// ret.push('</div>');
					// ret.push('</p>');
					ret.push('</li>');
					ret.push('</ul>');

					var html		= ret.join('');
					pages['content'].load(html);

					app.titlebar.left_nav('back');
					console.info('disclaimer.init');
				}; // EO: return disclaimer init

			}(),  // EO: return disclaimer init
		},

        'imprint': {
            'name':'imprint',
            'toolbar':false,
            'title':'Impressum',
            'content':'',
            'init':function(){

                return function(){
                    var ret = new Array();

                    ret.push(pages.imprint.menu('imprint'));
                    ret.push('<ul id="content_inner" class="imprint">');

                    ret.push('<li>');
                    ret.push('<h2>Impressum</h2>');
                    ret.push('<h3>Verantwortlich für den Inhalt:</h3>');
                    ret.push('<h4>AOK Rheinland/Hamburg – Die Gesundheitskasse</h4>');
                    ret.push('<p>');
                    ret.push('	Kasernenstraße 61<br>');
                    ret.push('	40213 Düsseldorf');
                    ret.push('</p>');
                    ret.push('<p>');
                    ret.push('	Telefon: <a href="tel:004921187910">0211 8791-0</a><br>');
                    ret.push('	Telefax: <a href="tel:004921187911125">0211 8791-1125</a><br>');
                    ret.push('	E-Mail: <a href="mailto:aok@rh.aok.de?subject=AOKglutenfrei%20APP%20Impressum">aok@rh.aok.de</a>');
                    ret.push('</p>');
                    ret.push('');
                    ret.push('<h3>Rechtsform:</h3>');
                    ret.push('<p>');
                    ret.push('	Körperschaft des öffentlichen Rechts<br>');
                    ret.push('	<br>');
                    ret.push('</p>');
                    ret.push('<p>');
                    ret.push('Gemäß §13 SGB I sind die Sozialversicherungsträger verpflichtet, die Bevölkerung im Rahmen ihrer Zuständigkeit aufzuklären. Das Werk einschließlich aller seiner Teile ist urheberrechtlich geschützt. Jede Verwertung außerhalb der engen Grenzen des Urheberrechtsgesetzes ist ohne Zustimmung des Verlages unzulässig und strafbar. Dies gilt insbesondere für Vervielfältigungen, Übersetzungen, Mikroverfilmungen und die Einspeicherung und Verarbeitung in elektronischen Systemen.');
                    ret.push('</p>');
                    ret.push('<p>');
                    ret.push('Vertretungsberechtigt:<br /> \n' +
                        'Vorsitzender des Vorstandes<br /> \n' +
                        'Günter Wältermann');
                    ret.push('</p>');
                    ret.push('');
                    ret.push('<h3>Der direkte Weg für Ihre E-Mail:</h3>');

                    if(platform!='webapp'){
                        url = "cordova.InAppBrowser.open('https://rh.aok.de/kontakt-zu-ihrer-aok-rheinlandhamburg/kontaktformular/', '_blank', 'location=no,closebuttoncaption=Schliessen');"
                    } else {
                        url = 				"window.open('https://rh.aok.de/kontakt-zu-ihrer-aok-rheinlandhamburg/kontaktformular/', '_blank', 'location=no,closebuttoncaption=Schliessen');"
                    }

                    ret.push('<p>');
                    ret.push('	Fragen zur Mitgliedschaft, zu Leistungen oder allgemeine Fragen an die AOKs vor Ort? ');
                    ret.push('	Dann klicken Sie bitte <a href="javascript:void(0);" onclick="' + url + '">hier</a>.<br>');
                    ret.push('	Oder per E-Mail an <a href="mailto:aok@rh.aok.de?subject=AOKglutenfrei%20APP%20Impressum">aok@rh.aok.de</a>');
                    ret.push('</p>');
                    ret.push('');
                    ret.push('<h3>Zuständige Aufsichtsbehörde:</h3>');
                    ret.push('	<p>');
                    ret.push('		Ministerium für Arbeit, Gesundheit und Soziales des Landes Nordrhein-Westfalen<br>');
                    ret.push('		Fürstenwall 25<br>');
                    ret.push('		40219 Düsseldorf<br>');
                    ret.push('</p>');
                    ret.push('');
                    ret.push('<h3>Umsatzsteueridentifikationsnummer:</h3>');
                    ret.push('<p>DE 207055164</p>');
                    ret.push('');
                    ret.push('<h3>Information nach § 36 VSBG</h3>');
                    ret.push('<p>');
                    ret.push('	Wir nehmen nicht an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil.');
                    ret.push('</p>');
                    ret.push('');
                    ret.push('<h3>Servicetelefon AOK-Clarimedis:</h3>');
                    ret.push('<p>');
                    ret.push('	Telefon: <a href="tel:08000326326">0800 0 326 326</a> * – 24 Stunden täglich für Sie da<br>');
                    ret.push('Unsere Berater erteilen AOK-Kunden Auskünfte am Telefon zu allen Fragen rund um Versicherung, Gesundheit und Medizin. Rufen Sie an – und die Mitarbeiter von AOK-Clarimedis helfen Ihnen gerne weiter. <br>');
                    ret.push('* kostenfrei aus dem deutschen Fest- und Mobilfunknetz</p>');
                    ret.push('');
                    ret.push('<h3>Verantwortlich für die App:</h3>');
                    ret.push('<p>Sandra Schmitz</p>');
                    ret.push('');
                    ret.push('<div>Produced by:</div>');
                    ret.push('<h3>grafix-house&reg;</h3>');
                    ret.push('<p>');
                    ret.push(	'Inhaber: G&ouml;khan Karaca<br />');
                    ret.push(	'Immermannstra&szlig;e 5<br/>');
                    ret.push(	'58453 Witten<br/>');
                    ret.push(	'Telefon:	+49.2302 / 171 45 85<br/>');
                    ret.push(	'Telefax:	+49.2302 / 171 45 84<br/>');
                    ret.push(	'Internet: www.grafix-house.com<br/>');
                    ret.push(	'E-Mail: <a href="mailto:info@grafix-house.com?subject=AOKglutenfrei%20APP%20Impressum"  on'+END_EV+'="document.location.href=\'mailto:info@grafix-house.com?subject=AOKglutenfrei%20APP%20Impressum\'">info@grafix-house.com</a><br/>');
                    // ret.push(	'Bild-Quellen: &copy;iStockphoto, &copy;Fotolia<br/>');
                    ret.push('');
                    ret.push('<h3>Bildrechte</h3>');
                    ret.push('<p>');
                    ret.push('Die Bildrechte liegen - soweit nicht anders vermerkt - bei den u.a. Künstlern.<br />');

                    ret.push('<br><br></p><h3>Fotolia</h3>© Kostrez, © S.Kobold, © M.studio, © HLPhoto, © vlentz, © Peredniankina, © Gabi Günther, © Giuseppe Porzani, © yannick vincent, © A_Lein, © Barbara Pheby, © Igor Dutina, © manla, © Digifood, © M.studio, © Natalikaevsti, © racamani, © Carmen Steiner, © Ideenkoch, © Inga Nilsen - Fotolia, © vasi_100, © Lilyana Vynogradova, © Angela Shirinov, © ott, © Lilyana Vynogradova, © Heike Rau, © stefaniav, © zoryanchik - Fotolia, © Drivepix, © kostrez, © victoria p., © Barbara Pheby, © Ideenkoch, © Monkey Business, © Dreamer12, © Corinna Gissemann, © legaa - Fotolia, © Zoryanchik, © Kathleen Rekowski, © FOOD-images, © Kitch Bain, © M.studio, © mythja, © Peredniankina, © Marén Wischnewski, © Jack Jelly, © Swetlana Wall, © Christian Jung, © Picture Partners, © keko64, © evgenyb, © Yevgeniya Shal, © Kathleen Rekowski, © InnEssA, © cook-and-style, © rossadisera, © Bernd Jürgens, © teressa, © doris oberfrank-list, © Marzia Giacobbe, © bellesia, © Quade, © Heike Rau, © HLPhoto, © Marco Mayer, © S.E. Shooting, © Birgit Reitz-Hofmann, © Sea Wave, © Daorson, © Thomas Francois, © Darius Dzinnik, © Lsantilli, © ebnersim, © emmi, © eyewave, © PhotoSG, © HLPhoto, © Eve, © Mi.Ti. - Fotolia, © olhaafanasieva, © kab-vision');
                    ret.push('<br><br><h3>iStockPhoto</h3>© Funwithfood, © nicolesy, © Mark Gillow, © Shaiith, © jongallas, © alexeys - iStockPhoto, © HuePhotography, © edoneil, © LynnSeeden, © pacifica, © gbh007, © IngaNielsen, © george tsartsianidis, © ajafoto, © Marians7D, © SilviaJansen, © svariophoto, © UTurnPix, © FernandoAH');
                    /* --- generator ---
                    var recipes_sorted = _.sortBy(recipes.items, function(obj){ return obj['image']['@platform']; });
                    for (var i = 0; i < recipes_sorted.length; i++) {
                        var r=recipes_sorted[i];

                        if(!platform || platform != r['image']['@platform']){
                            ret.push('<br /><br /><h3>' + r['image']['@platform'] + '</h3>');
                        }
                        ret.push(r['image']['@licence'] + ', ');

                        var platform = r['image']['@platform'];
                    }
                    */

                    ret.push('<br /><br />Das Kopieren und unerlaubte Nutzen, sowohl bei kommerzieller als auch nicht-kommerzieller Nutzung ohne vorherige Genehmigung der jeweiligen Rechteinhaber ist untersagt.');
                    ret.push('</p>');
                    ret.push('</li>');

                    ret.push('<li>');
                    ret.push('<p>');
                    ret.push('<div on'+END_EV+'="app.reset.reload()">');
                    ret.push(	'<small>v'+app.version+'</small>');
                    ret.push('</div>');
                    ret.push('</p><p>&nbsp;</p><p>&nbsp;</p>');
                    ret.push('</li>');

                    ret.push('</ul>');

                    var html		= ret.join('');
                    pages['content'].load(html);

                    app.titlebar.left_nav('back');
                    console.info('imprint.init');
                }; // EO: return impress init

            }(),  // EO: return impress init

            'menu': function (id) {

                var tab_items = [
                    ['privacy', 'Datenschutzerklärung'],
                    ['privacy2', 'Datenschutzrechte', 'http://aok.de/rh/datenschutzrechte']
                ];

                var tab_items2 = [
                    ['imprint', 'Impressum'],
                    ['disclaimer', 'Haftungsbeschränkung']
                ];


                var ret = new Array();

                ret.push('<div id="imprint_menu" class="tbar_wrapper">');

                ret.push(	'<ul>');
                for (var i = 0; i < tab_items.length; i++) {
                    var item = tab_items[i];
                    act = (item[0]==id)?'active':'',
                        url = '';

                    ret.push(		'<li class="'+act+'">');
                    if(item[0]==='privacy2'){
                        if(platform!='webapp'){
                            url = "cordova.InAppBrowser.open('"+item[2]+"', '_blank', 'location=no,closebuttoncaption=Schliessen');"
                        } else {
                            url = 				"window.open('"+item[2]+"', '_blank', 'location=no,closebuttoncaption=Schliessen');"
                        }
                        ret.push(			'<a href="javascript:;" on'+END_EV+'="' +url+ '">'+item[1]+'</a>');
                    }else {
                        ret.push(			'<a href="javascript:;" on'+END_EV+'="app.hash.set(\''+item[0]+'\');">'+item[1]+'</a>');
                    }

                    ret.push(		'</li>');
                }
                ret.push(	'</ul>');

                ret.push(	'<ul>');
                for (var i = 0; i < tab_items2.length; i++) {
                    var item = tab_items2[i];
                    act = (item[0]==id)?'active':'';
                    ret.push(		'<li class="'+act+'">');
                    ret.push(			'<a href="javascript:;" on'+END_EV+'="app.hash.set(\''+item[0]+'\');">'+item[1]+'</a>');
                    ret.push(		'</li>');
                }
                ret.push(	'</ul>');

                ret.push('</div>');

                var html		= ret.join('');
                return html;
            }
        },



    }; // return ...
}();

// function clone(template) {
//   this.init					=	template.init;
//   this.page					=	template.page;
//   this.check				=	template.check;
//   this.isActive			=	template.isActive;
//   this.setItem				=	template.setItem;
//   this.setStatus			=	template.setStatus;
//   this.button				=	template.button;
//   this.setButtons			=	template.setButtons;
//   this.badge				=	template.badge;
//
// }
// pages.favorites			= new clone(pages.template);
// pages.shopping_list		= new clone(pages.template);

pages.favorites['init']					=	pages.template.init;
pages.favorites['page']					=	pages.template.page;
pages.favorites['where']				=	pages.template.where;
pages.favorites['set']					=	pages.template.set;
pages.favorites['check']				=	pages.template.check;
pages.favorites['isActive']			=	pages.template.isActive;
pages.favorites['setItem']				=	pages.template.setItem;
pages.favorites['setStatus']			=	pages.template.setStatus;
pages.favorites['button']				=	pages.template.button;
pages.favorites['setButtons']			=	pages.template.setButtons;
pages.favorites['badge']				=	pages.template.badge;

pages.shopping_list['init']			=	pages.template.init;
pages.shopping_list['page']			=	pages.template.page;
pages.shopping_list['where']			=	pages.template.where;
pages.shopping_list['set']				=	pages.template.set;
pages.shopping_list['check']			=	pages.template.check;
pages.shopping_list['isActive']		=	pages.template.isActive;
pages.shopping_list['setItem']		=	pages.template.setItem;
pages.shopping_list['setStatus']		=	pages.template.setStatus;
pages.shopping_list['button']			=	pages.template.button;
pages.shopping_list['setButtons']	=	pages.template.setButtons;
pages.shopping_list['badge']			=	pages.template.badge;

// pages.recipes['favorite']				=	pages.favorites;
// pages.recipes['shopping_list']		=	pages.shopping_list;