// window.localStorage.clear(); // test initial start

//alert( window.outerHeight )

var debug_counter = 0;

// var isStandalone = (typeof window.navigator["standalone"] !== 'undefined')?window.navigator["standalone"]:false;

var ua = navigator.userAgent;
// var android = (/android/i.test(ua));
// var ios = (/iPhone|iPad|iPod/i.test(ua));
// var wp8 = (/iemobile/i.test(ua));

// var isAndroid = /android/i.test(ua);
// var isGingerbread = /android 2\.3/i.test(ua);
// var isHoneycombOrNewer = /android [3-9]/i.test(ua);
// var isFroyoOrOlder = isAndroid && !isGingerbread && !isHoneycombOrNewer;

// var android2 = /android 2\.3/i.test(ua);

var scroller, startScroll;
var app = function(){
	return {

	id: 'app',
	name: 'app',
	obj: $('#app'),
	height: $('#app').height(),
	window_height: function () {
		return function() {
			var wh = window.innerHeight;
			// if (parseFloat(window.device.version) === 7.0) {
			if ( ios7 ) {
				// document.body.style.marginTop = "20px";
				wh = wh-20;
			}
			return wh;
		}
	}(),

	version:'3.1.0',
	startpage:['start'],

	getName: function(args){
		for (var o in this) {
			if (args.callee===this[o]){
				console.log('############');
				console.log(o);
				console.log('############');
				return o;
			}
		}
	},

	// Application Constructor
	initialize: function() {

	    if ( document.location.hash === "#start"){
            document.location.hash = "";
        }

        if ( iosx ) {
        	$('html').addClass('iosx');
        }

        if(typeof window.cordova !== "undefined"){
            var platformId = window.cordova.platformId;
            if (platformId) {
                document.body.classList.add('is-' + platformId);
            }
		}

		this.bindEvents();
	},
	reset: function(){

		return {
			name:'reset',
			reset_counter:0,
			reload: function(){

				// var name = arguments.callee.toString(0)
				// console.log('app.'+name+'.reload() -> '+app.reset.reset_counter);

				app.reset.reset_counter++;
				if (app.reset.reset_counter>=3) {
					window.localStorage.clear();
					window.location.reload();
					app.reset.reset_counter=0;
				} else {
					setTimeout(function(){
						app.reset.reset_counter=0;
					}, 3000);
				}

				console.log(app.reset.reset_counter);

			},
			generateQuickGuid: function(len) {
				var l = (len)?len:32;
				var f = l/13;
				var rdStr = [];
				for (var i = 0; i < f; i++) {
					var rd = Math.random().toString(36).substring(2, 15);
					rdStr.push(rd);
				}
				return rdStr.join('').substring(0, l);
			}
		}
	}(),

	// Bind Event Listeners
	//
	// Bind any events that are required on startup. Common events are:
	// 'load', 'deviceready', 'offline', and 'online'.
	bindEvents: function() {

		return function() {

			debug_counter=debug_counter+1;
            console.info('bindEvents');
			console.dir(debug_counter);

            window.setTimeout(function(){

            	var body_height = $('body').height(),
					htmlDoc = $('html');
            	if(iosx){
                    body_height = body_height - htmlDoc.css('padding-top') - htmlDoc.css('padding-bottom');
				}

                $('#content_wrapper').height( body_height - $('#titlebar').height() - $('#toolbar').height() );
            }, 100);
			app.spinner.init();

			// bei updates kann hier der timestamp und die Versionsnummer abgefragt werden um die App neu zu installieren
			if (
				parseFloat(app.db.get('version')) < parseFloat(app.version)
			) {
				app.db.set('init', 'false'); // reinstall app
				console.log('REINSTALL APP we want test new data or we reloeased new version -> INIT=FALSE')
			}


			if (app.db.get('init') === 'true') {

				// document.getElementById('app').style.height=window.outerHeight;

				console.log('\n\nbindEvents -> init');

				// document.addEventListener('touchmove', function (e) { e.preventDefault(); }, false);
				if (platform === 'webapp') {
					window.addEventListener('DOMContentLoaded', this.onDeviceReady, false);
				} else {
					document.addEventListener('deviceready', this.onDeviceReady, false);
				}

				// FastClick.attach(document.body);

				window.addEventListener("hashchange", this.hash.listener, false);

				var supportsOrientationChange = "onorientationchange" in window,
					orientationEvent = supportsOrientationChange ? "orientationchange" : "resize";
				window.addEventListener(orientationEvent, function(){
					window.location.reload();
					// var origin = window.location.origin;
					// var hash = window.location.hash;
					// var time = '?t='+((new Date().getTime()).toString().substr(-6));
					// var new_href = origin+time+hash;
					//
					// console.dir('new_href -> '+new_href)
					//
					// window.location.href = new_href;
				}, false);

				pages.recipes.preloadImages();

				// console.log('\n\n################# \n');
				window.recipes = app.db.get('recipes');
				// console.log('window.recipes -> '+window.recipes);

				setTimeout(function(){
					pages['favorites'].badge();
					pages['shopping_list'].badge();
				}, 500);

				// console.log('app.toolbar.badge favorites='+pages.favorites.where().length);
				// console.log('app.toolbar.badge shopping_list='+pages.shopping_list.where().length);
				// console.log('\n\n################# \n');

				_.delay(app.spinner.end, 500);
				//app.spinner.end();


			} else {
				// populate DB !!!!



				app.db.set('version', app.version);
				if (app.db.get('userid')==null) {
					app.db.set('install', new Date().getTime().toString());
				}
				if (app.db.get('userid')==null) {
					app.db.set('userid', app.reset.generateQuickGuid(40));
				}

				app.populateDB(function(data){

					console.log('\n\napp.populateDB -> callBack');
					console.log(data);

					app.spinner.end();
					app.db.set('recipes', JSON.stringify(data));
					app.db.set('favorites', '[]');
					app.db.set('shopping_list', '[]');
					app.db.set('init', 'true');

					if (debug_counter<=4) {
						app.bindEvents();
					}

				})
			}
		}; // EO: return app.bindEvents
	}(), // EO: app.bindEvents

	populateDB: function ( cbFunction ) {

        this.cbFunction = cbFunction;

		return function ( cbFunction ) {
			console.log('\n\npopulateDB');

			var data_files = {
			// CategorieName: 	FileName
				'Backrezepte':		'01_aok_zoeliakie_backrezepte',
				'Suppen':			'02_aok_zoeliakie_suppen',
				'Vorspeisen':		'03_aok_zoeliakie_vorspeisen',
				'Hauptgerichte':	'04_aok_zoeliakie_hauptgerichte',
				'Desserts':			'05_aok_zoeliakie_desserts'
			}, cat;
			recipes = window.recipes;
			recipes.count = 0;
			recipes['items'] = [];
			for(cat in data_files){
				var category = cat;
				var category_key = data_files[cat];

				recipes['categories'][cat] = [];

				var cat_obj = data_recipes[ data_files[cat] ];
				var cat_recipes = cat_obj['recipes']['recipe'];
				var cat_recipes_length = cat_recipes.length;

				console.log(category);
				console.log(category_key);
				console.log(cat_recipes);

				for (var i = 0; i < cat_recipes_length; i++) {
					recipes.count++;
					var recipe = cat_recipes[i];

					recipe['id'] = recipes.count;
					recipe['category'] = cat;

					recipes.items.push(recipe);
					recipes.categories[cat].push(recipes.count);
				}
			}

			if (cbFunction) {
				cbFunction(recipes);
			}
		}; // EO: return app.populateDB

	}(), // EO: app.populateDB

	// deviceready Event Handler
	//
	// The scope of 'this' is the event. In order to call the 'receivedEvent'
	// function, we must explicity call 'app.receivedEvent(...);'
	onDeviceReady: function() {

		return function() {
			setTimeout(app.scroller.init, 200);
			app.toolbar.init();

			// var hash = _.values(app.hash.get());
			// // alert(JSON.stringify(hash))
			//
			// var hashParam = _.without(hash, false, "false");
			// // alert('hashParam='+JSON.stringify(hashParam))
			// // alert(_.isArray(hashParam))
			//
			// var loading_page = (_.isArray(hashParam)) ? hashParam[0] : app.startpage;
			// // alert(JSON.stringify(loading_page) +' ---- '+ _.isArray(loading_page))

			var loading_page = app.startpage;

			app.receivedEvent(loading_page);

		}; // EO: return app.onDeviceReady
	}(), // EO: app.onDeviceReady

	// Update DOM on a Received Event
	receivedEvent: function(id) {

	    this.id = id;

		return function(id) {
			console.log('receivedEvent='+id);

			window.location.hash=id;
			if (id === app['startpage'][0]) {
				app.setContent(app.startpage);
			}
		}; // EO: return app.receivedEvent
	}(), // EO: app.receivedEvent

	setContent: function(aid, func){

	    this.aid = aid;
	    this.func = func;

		return function(aid, func){
			console.log(['---- setContent ----', JSON.stringify(aid)]);

            var id, pageObj, pageInit;

			if (aid !== app['startpage'][0]) {
				window.clearInterval(pages.start.interval);
			}

			// initial method
			if (Array.isArray(aid) && aid.length === 1) {
				 id = aid;
				 pageObj = pages[id];
				 document.getElementById('scroller').innerHTML = pageObj['content'];
				 pageInit = function(){
					 pages[id].init();
				 };
				 app.titlebar.left_nav('info');
			}
			// method page with only one param
			else if (Array.isArray(aid) && aid.length === 2) {
				id = aid[0];
				pageObj = pages[aid[0]];
				pageInit = function(){
					pages[id].page(aid[1]);
				};
				app.titlebar.left_nav('back');
			}
			// individual method
			else if (Array.isArray(aid) && aid.length === 3) {
				id = aid[0];
				pageObj = pages[aid[0]];
				pageInit = function(){
					pages[id][aid[1]](aid[2]);
				};
				app.titlebar.left_nav('back');
			}
			// if we get an error
			else {
				alert('setContent: ERROR'+ aid);
				return false;
			}

			app.titlebar.title(pages[id]['title']);
            document.body.className = pages[id]['name'];

			pageInit();
			app.toolbar.enable(id);

			if (func) {
				func();
			}

			// console.log('Received Event: ' + aid);
		}; // EO: return app.setContent
	}(), // EO: app.setContent

	helper: function(){
		return {
			randomColor: function(){
				return "#"+((1<<24)*Math.random()|0).toString(16);
			}
		};
	}(),

	// MODULES

	hash: {
		listener: function(){

			return function(){
				// app.scroller.copy();

				var hash = window.location.hash.split('#')[1];
				//console.info([1, 'hash.listener', hash]);
				// ((href.indexOf('?')>-1)?href.split('?')[0]:href)+'?t='+time;

				if (hash) {
					var hashParams = hash.split(',');
					// console.log(['app.hash.listener -> hashParams', hashParams])

					app.setContent(hashParams, function(){

						window.setTimeout(function () {
							app.scroller.refresh();
							app.scroller.scrollTo(0, 0, 0);
						}, 200);
						// console.info([2, 'hash.listener', hashParams]);
					});
				}
			}; // EO: return app.hash.listener
		}(), // EO: app.hash.listener

		set: function(hash){

		    this.hash = hash;

			return function(hash){

				// alert('hash.set'+hash)

				var ret='#';
				if (_.isUndefined(hash)) {
					ret=app['startpage'][0];
				}
				else if (_.isString(hash)) {
					if (hash.indexOf('#') === 0) {
						ret=hash;
					} else {
						ret+=hash;
					}
				}
				else if (_.isArray(hash)) {
					/*
					input		= ['recipes', 'page', 1]
					output	= '#recipes,page,1'
					call		= app.hash.set(['recipes', 'page', 1])
					*/
					ret+=hash.join(',');
				}
				else if (_.isObject(hash)) {
					/*
					input		= {
										page:'recipes',
										method:'page',
										number:1
									}
					output	= '#recipes,page,1'
					call		= app.hash.set({page:'recipe', method:'page', number:1})
					*/
					ret += _.toArray(hash).join(',');
				}
				window.location.hash=ret;
			}; // EO: return app.hash.set
		}(), // EO: app.hash.set

		get: function(){

			return function(){
				var hash = window.location.hash.slice(1);
				hash = hash.split(',');
				var ret = {};
				var h0 = _.isUndefined(hash[0]);
				var h1 = _.isUndefined(hash[1]);
				var h2 = _.isUndefined(hash[2]);
				if (hash.length > 0) {
					ret['page']   = ( h0 )? false: hash[0];
					ret['method'] = ( h1 )? false: (h2) ? 'page': hash[1];
					ret['number'] = ( h2 && !h1 )? hash[1]: (h2) ? false: hash[2];
				}
				// return object {page: "recipes", method: "page", number: "1"}
				return ret;
			}; // EO: return app.hash.get
		}() // EO: app.hash.get

	},

	db: {
		name:'db',
		set: function(key, value){

            this.key = key;
            this.value = value;

			return function(key, value){
				// console.log('app.'+this.name+'.set('+key+', '+ JSON.stringify(value).substring(0,350)+'... '+(typeof value)+' )');
				var v;

				if (_.isString(value)) {
					v = value;
				} else {
					v = JSON.stringify(value);
				}
				window.localStorage.setItem(key, v);
			}; // EO: return app.db.set
		}(), // EO: app.db.set

		get: function(key, object_key){

            this.key = key;
            this.object_key = object_key;

			return function(key, object_key){
				console.log('app.'+this.name+'.get('+key+', '+object_key+')');

				if (key in window.localStorage) {

					var data = window.localStorage.getItem(key);
					if (data.match(/^[\[|\{]/g) === null) {
						return data;
					} else {
						if (object_key) {
							var data = JSON.parse(window.localStorage.getItem(key));
							return data[object_key];
						}
					}
					return JSON.parse(window.localStorage.getItem(key));
				} else {
					return null;
				}
			}; // EO: return app.db.get
		}(), // EO: app.db.get

		getAll:function(){
			console.log('app.'+this.name+'.getAll()');

			return window.localStorage;
		},// EO: app.db.getAll

		find: function(q, search_in_value){

		    this.q = q;
		    this.search_in_value = search_in_value;

			return function(q, search_in_value){
				console.log('app.'+this.name+'.find('+q+', '+search_in_value+')');

				var ls = app.db.getAll(),
                    ret = {}, key;
				for(key in ls){
					if (search_in_value) {
						var value = ls[key];
						if(key.match(q) || value.match(q)){
							ret[key] = JSON.parse(ls[key]);
						}
					} else {
						if(key.match(q)){
							ret[key] = JSON.parse(ls[key]);
						}
					}
				}
				return ret;
			}; // EO: retrun app.db.find
		}(), // EO: retrun app.db.find


		getCSV: function (obj) {

		    this.obj = obj;

			return function(obj){

				// here we need iterating all fields

				var r = [];
				for (var i = 0; i < obj.length; i++) {
					r.push( obj[i]['id']+","+obj[i]['title'] );
				}
				$('html').html("<pre>"+r.join("\n")+"</pre>");

			}; // EO: retrun app.db.getCSV
		}(), // EO: retrun app.db.getCSV


	},

	spinner: {
		name:'spinner',

		event:function (e) {
			e.preventDefault();
			e.stopPropagation();
		},

		init: function(){

			//var spinner = document.getElementById(app.spinner.name);
			spinner.addEventListener('touchstart', app.spinner.event, false);

			if (ios) {
				$(spinner).attr('class', 'ios');
			} else if (android4) {
				$(spinner).attr('class', 'android4');

			} else if (android2) {
				$(spinner).attr('class', 'android2');

			} else {
				//
			}

			console.log('app.spinner.init();')

		}, // EO: app.spinner.init

		start: function(cb, time){

		    this.cb = cb;
		    this.time = time;

			return function(cb, time){
				$('#'+app.spinner.name).addClass('active');
				//spinner.addEventListener('touchstart', app.spinner.event, false);

				if (cb) {
					cb();
				}
				setTimeout(function () {
					app.spinner.end();
				}, ((time)?time:750) );

				console.log('app.spinner.start();')
			}; // EO: retrun app.spinner.start
		}(), // EO: app.spinner.start

		end: function(cb){

		    this.cb = cb;

			return function(cb){
				$('#'+app.spinner.name).removeClass('active');
				//spinner.removeEventListener('touchstart', app.spinner.event, false);

				if (cb) {
					cb();
				}
				console.log('app.spinner.end();')
			}; // EO: return app.spinner.end
		}(), // EO: app.spinner.end

		stop:function(){
			app.spinner.end();
			console.log('app.spinner.stop();')
		} // EO: app.spinner.stop
	},

	titlebar: {
		id: 'titlebar',
		name: 'titlebar',
		obj: $('#titlebar'),
		height: $('#titlebar').height(),

		clickBack:false,

		title: function (title) {

		    this.title = title;

			return function (title) {
				document.getElementById('title').innerHTML = title;

			}; // EO: return app.titlebar.title
		}(), // EO: app.titlebar.title

		left_nav: function (opt){

			return function (opt){
				var obj = document.getElementById('left_nav');
				var r = '';
				if (opt === 'back') {
					r = '<a class="button back" on'+END_EV+'="history.go(-1);">Zur&uuml;ck</a>';
					//r = '<a class="button back" href="#'+app.hash.get()['page']+'">Zur&uuml;ck</a>';
				} else if (opt === 'info'){
					r = '<a class="button info" on'+END_EV+'="app.hash.set(\'#imprint\');">&nbsp;</a>';
				}
				obj.innerHTML = r;
			}; // EO: return app.titlebar.left_nav
		}() // EO: app.titlebar.left_nav
	},

	toolbar: {
		id: 'toolbar',
		name: 'toolbar',
		obj: $('#toolbar'),
		height: $('#toolbar').height(),

		elements: [],

		init: function () {

			return function () {
				var r = [];
				r.push('<ul>');
				for(page in pages){
					var p = pages[page];

					if (p['toolbar']) {

						app.toolbar.elements.push(page);

						r.push('<li id="'+page+'"'+((page === app.startpage[0])?' class="active"':'')+'>');
						r.push('<a on'+START_EV+'="app.scroller.lastY=0;app.hash.set(\''+page+'\');">');
						if (p['badge']) {
							r.push('<small class="badge">0</small>');
						}
						r.push('<em>&nbsp;</em>');
						r.push('<strong>'+((p['nav_title'])?p['nav_title']:p['title'])+'</strong>');
		 				r.push('</a>');
						r.push('</li>');
					}

				}
				r.push('</ul>');
				document.getElementById('toolbar').innerHTML=r.join('');
                //for(page in pages){
                //    $('#'+page).on('click', function(e){
                //        app.scroller.lastY=0;
                //        app.hash.set(page);
                //        e.preventDefault();
                //    });
                //}

            // $('#'+app.toolbar.id).html(r.join(''));

            var itemWidth = 100/app.toolbar.elements.length;
            $('#'+app.toolbar.id+' li').css({
               'width': itemWidth+'%'
            });

			}; // EO: return app.toolbar.init
		}(), // EO: app.toolbar.init

		enable: function (id) {

		    this.id = id;

			return function (id) {
				$('#'+app.toolbar.id+' li').removeClass('active');
				$('#'+app.toolbar.id+' li#'+id).addClass('active');
			}; // EO: return app.toolbar.enable
		}(), // EO: app.toolbar.enable

		badge: function (id, num) {

		    this.id = id;
		    this.num = num;

			return function (id, num) {
				var badge = $('#toolbar #'+id+' .badge');
				if (num>0) {
					badge.addClass('activate');
				} else {
					badge.removeClass('activate');
				}
				if (num === 0) {
					setTimeout(function () {
						badge.html(num);
					}, 220);
				} else {
					badge.html(num);
				}
			}; // EO: return app.toolbar.badge
		}(), // EO: app.toolbar.badge
	},

	'scroller': {
		name: 'scroller',
		init: function () {

			//-----------------------------------------------------------------------------
			//
			// Doubleclick fix for Androids 4.2x where a single tap results
			// in a double tap | click
			// Solution found here:
			// http://stackoverflow.com/questions/14982864/phonegap-2-4-0-with-android-4-2-strange-double-click-behaviour
			//
			//-----------------------------------------------------------------------------
/*
			last_click_time = new Date().getTime();
			document.addEventListener('click', function (e) {
			click_time = e['timeStamp'];
			if (click_time && (click_time - last_click_time) < 500) {
			e.stopImmediatePropagation();
			e.preventDefault();
			return false;
			}
			last_click_time = click_time;
			}, true);
*/


			//// INIT iScroll
			//scroller = new iScroll('content', {
			//	hScroll        : false,
			//	vScroll        : true,
			//	hScrollbar     : false,
			//	vScrollbar     : true,
			//	// fixedScrollbar : true,
			//	fadeScrollbar  : false,
			//	hideScrollbar  : true,
			//	bounce         : true,
			//	momentum       : true,
			//	// lockDirection  : true,
			//	// useTransition  : true,
			//	// useTransform   : true,
			//	// handleClick    : true,
			//	click          : true,
             //   tap            : true,
			//	checkDOMChanges: true,
            //
			//	//onBeforeScrollStart: function (e) { e.stopImmediatePropagation(); },
			//	onScrollStart: function (e) {
			//		e.preventDefault();
			//	},
			//	onBeforeScrollMove: function(e) {
			//		e.stopPropagation();
			//	},
			//	// onBeforeScrollStart: function (e) {
			//	//
			//	// 	e.stopImmediatePropagation();
			//	//
			//	//    var nodeType = e.explicitOriginalTarget ? e.explicitOriginalTarget.nodeName.toLowerCase():(e.target ? e.target.nodeName.toLowerCase():'');
			//	// 	if(
			//	// 		nodeType !='select'
			//	// 		&& nodeType !='option'
			//	// 		&& nodeType !='input'
			//	// 		&& nodeType!='textarea'
			//	// 		&& nodeType!='a'
			//	// 	) {
			//	// 		e.preventDefault();
			//	// 	}
			//	// },
			//	// onScrollEnd: function(){
			//	// 	// var x = scroller.x;
			//	// 	// var y = scroller.y;
			//	// 	// app.scroller.x = x;
			//	// 	// app.scroller.y = y;
			//	// }
			//}); // iscroll 4.2.5

            //var ua = navigator.userAgent;
            //var android4 = /android 4/i.test(ua);
            //var android5 = /android 5/i.test(ua);
            //var chrome = /Chrome\//i.test(ua);
            //
            ////if(android4 || android5){
            //if(!android4 && !chrome){

                // INIT IScroll v5.2.0
				/*
                scroller = new IScroll('#content', {
                    hScroll        : false,
                    vScroll        : true,
                    hScrollbar     : false,
                    vScrollbar     : true,
                    // fixedScrollbar : true,
                    fadeScrollbar  : false,
                    hideScrollbar  : true,
                    bounce         : true,
                    momentum       : true,
                    // lockDirection  : true,
                    // useTransition  : true,
                    // useTransform   : true,
                    // handleClick    : true,
                    click          : true,
                    tap            : 'tapEvt',
                    checkDOMChanges: true,

                    //onBeforeScrollStart: function (e) { e.stopImmediatePropagation(); },
                    onScrollStart: function (e) {
                        e.preventDefault();
                    },
                    onBeforeScrollMove: function(e) {
                        e.stopPropagation();
                    },
                    //// onBeforeScrollStart: function (e) {
                    //
                    // 	e.stopImmediatePropagation();
                    //
                    //    var nodeType = e.explicitOriginalTarget ? e.explicitOriginalTarget.nodeName.toLowerCase():(e.target ? e.target.nodeName.toLowerCase():'');
                    // 	if(
                    // 		nodeType !='select'
                    // 		&& nodeType !='option'
                    // 		&& nodeType !='input'
                    // 		&& nodeType!='textarea'
                    // 		&& nodeType!='a'
                    // 	) {
                    // 		e.preventDefault();
                    // 	}
                    // },
                    // onScrollEnd: function(){
                    // 	// var x = scroller.x;
                    // 	// var y = scroller.y;
                    // 	// app.scroller.x = x;
                    // 	// app.scroller.y = y;
                    // }
                }); // iscroll 5.2.0
				*/

            //}
		},
		// init: function () {
		// 	scroller = new IScroll('#content', {
		// 		mouseWheel: true,
		// 		click: true,
		// 	});
		// },
		x:0,
		y:0,
		lastY:0,
		scrollBack: function () {
			// console.log('### scroll 1 ### '+app.scroller.lastY)
			window.setTimeout(function () {
				var y = app.scroller.lastY;
				var time = 150;
				var relative = false;
                app.scroller.scrollTo(0, y, time, relative);
				console.log('### scroll 2 ### 0, '+app.scroller.lastY+', '+time+', '+relative)
			}, 320);
		},
		copy: function(){
			app.scroller.lastX = app.scroller.x;
			app.scroller.lastY = app.scroller.y;
			console.log('y='+app.scroller.y);
			console.log('lastY='+app.scroller.lastY);
		},

		destroy: function () {
			// scroller.destroy();
		},
		refresh: function () {
			setTimeout(function () {
				// scroller.refresh();
			}, 100);
		},
		scrollTo: function (x, y, time, relative) {
			// scroller.scrollTo(x, y, time, relative)
            // scroller_container.style.top='44px';
            // window.scrollTo(x, y);
            //content_wrapper.scrollTo(x, y);

            $scrollingContainer=$('#content_wrapper');
            $scrollingContainer.animate({
                scrollTop: y
            }, time);

		},
		scrollToElement: function (el, time){
			// scroller.scrollToElement(el, time);

            $scrollingContainer=$('#content_wrapper');
            var vpHeight = $scrollingContainer.height(),
				scrollTop = $scrollingContainer.scrollTop(),
				link = $(el),
				position = link.position();

            $scrollingContainer.animate({
                scrollTop: (position.top + scrollTop)
            }, 500);

		},
		scrollToPage: function (pageX, pageY, time) {
			// scroller.scrollToPage(pageX, pageY, time);
		},
		disable:function(){
			// scroller.disable();
		},
		enable:function(){
			// scroller.enable();
		},
		zoom: function (x, y, scale, time){
			// scroller.zoom(x, y, scale, time);
		},
		isReady: function (){
			// scroller.isReady();
		}
	}, // EO: app.scroller

	clickDelay: {
		current_time:function(){
			return new Date().getTime();
		},
		check: function () {
			var time = new Date().getTime();
			return (time > clicked_current_time + 500) ? false : true;
		}
	},

	form: {
		select: {
			focus: function(id, event, debug_act){

				if (android2
					// || (typeof debug_act!='undefined' && debug_act==true)
					// || platform=='webapp'
				) {
					var selectObj = $('select#'+id);
					var options = selectObj.find('option');
					var selectInput = $('div.'+id);
					if (selectInput.length === 0) {
						input_field='<div';
						input_field+=' class="'+id+'"';
						input_field+=' onclick="app.form.select.focus(\''+id+'\', event);"';
						input_field+=' />'+selectObj.val()+'</div>';
						selectObj.after(input_field);
					}
					selectObj.hide();

					var selectboxJS = $('#selectboxJS');
					if (selectboxJS.length === 0) {
						var r='';
						r+='<div id="selectboxJS">';
						r+=	'<div class="option_wrapper">';
						options.each(function (i,e) {
								var value = $(this).val(),
                                    text = $(this).text(),
                                    checked=(selectObj.val()==value)?' class="checked"':'';

								r+='<a';
								r+=' href="javascript:;"';
								r+=' id="radio_'+i+'"';
								r+=' onclick="';
								r+=	'app.form.select.onchange(\''+id+'\', \''+value+'\');';
								r+=	'" ';
								r+=	checked;
								r+='>';
								r+='<div class="txt">'+text+'</div>';
								r+='<div class="radio"><span class="radio '+checked+'">&nbsp;</span></div>';
								r+='</a>';
						});
						r+=	'</div>';
						r+='</div>';
						$('body').append(r);
						delete selectboxJS;
						var selectboxJS = $('#selectboxJS');
						selectboxJS.bind('touchmove', function (evt) {
							evt.preventDefault();
							evt.stopPropagation();
						})
						selectboxJS.bind('click', function (evt) {
							selectboxJS.fadeOut(function () {
								selectboxJS.remove();
							});
						});
					} else {
						options.each(function (i,e) {
							var value = $(this).val();
							if ((selectObj.val() === value)) {
								$('#radio_'+i).addClass('checked');
							} else {
								$('#radio_'+i).removeClass('checked');
							}
						});
						selectboxJS.fadeIn(function () {
							selectboxJS.css({
								'display':'block',
								'opacity':'1.0'
							});
						});
					}

					if (event) {
						event.preventDefault();
						event.stopPropagation();
					}


				}

			}, // EO: app.form.select.focus

			onchange: function(id, v){

				var selectObj = $('select#'+id),
                    selectInput = $('div.'+id),
                    selectboxJS = $('#selectboxJS');

				selectboxJS.fadeOut(function () {
					selectboxJS.css({
						'display':'none'
					});
				});

				var options = selectObj.find('option');
				options.each(function (i,e) {
					var value = $(this).val();
					var text = $(this).text();
					if (value === v) {
						selectObj.val(value);
						selectInput.text((value !== '')?value:text);

						document.getElementById(id).selectedIndex = i;
						$(this).attr('selected', 'selected');
					} else {
						$(this).removeAttr('selected');
					}
				});
				$('form#searchform_form').submit();

			}, // EO: app.form.select.onchange

		},  // EO: app.form.select

	}, // EO: app.form




	notification: function(){

		return {

			title:'AOKglutenfrei',

			alert: function(msg, cb, title, buttonLabels){
				if (platform === 'webapp') {
					alert(msg);
				} else {
					navigator.notification.alert(
						msg,
						(cb)?cb:function(){},
						(title)?title:app.notification.title,
						(buttonLabels)?buttonLabels:'Ok'
					);
				}
			}, // EO: app.notification.alert

			confirm: function(msg, cb, title, buttonLabels){

				// alert('confirm: function('+msg+', '+cb+', '+title+', '+buttonLabels+'){');

				if (platform === 'webapp') {
					var check = confirm(msg);
					/*
					 * Return Parameter: buttonIndex
					 * Abbrechen = 1
					 * Ok = 2
					 */
					var buttonIndex=(check)?2:1;
					if (check === false) {
						return false;
					} else {
						if(cb){ cb(buttonIndex); }
						return true;
					}
				} else {
					navigator.notification.confirm(
						msg,
						(cb)?cb:function(){},
						(title)?title:app.notification.title,
						(buttonLabels)?buttonLabels:'Abbrechen,Ok'
					);
				}
			}, // EO: app.notification.confirm

		}; // EO: retrun app.notification
	}(), // EO: app.notification

	};
}();
// document.addEventListener('DOMContentLoaded', app.onDeviceReady, false);
